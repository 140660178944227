import React, { useRef } from "react";

const Partner = ({ partnerRef }) => {
  return (
    <section ref={partnerRef}>
      <div className="bg-gradient lg:grid lg:grid-cols-2 flex flex-col text-white lg:px-20 px-10 py-10 gap-8 relative">
        <div className="z-10">
          <h2 className="text-lg font-medium">
            Become Our Bulk SMS Partner / Bulk SMS Resellers
          </h2>
          <p>
            We welcome bulksms partners throughout India and invites everyone to
            be a part of the our one of the fastest growing company. We, at
            SMSRootIndia offers cheapest bulk sms packages to encourage more and
            more bulk sms resellers and partners. Our White lable reseller sms
            panel offers all our resellers to manage their clients on their own
            by maintaining their own brand name. They can easily maintain their
            own brand without letting their customers know about the Source
            providers.
          </p>
        </div>
        <div className="z-10">
          <h2>Terms & Conditions</h2>
          <li className="text-lg mt-2">
            Promotional Bulksms delivers between 9am To 9pm & will not deliver
            to DND customers.
          </li>
          <li className="text-lg mt-2">
            SMS API will not available on Promotional SMS.
          </li>
          <li className="text-lg mt-2">
            Transactional bulksms can be sent 24 x 7 & can be sent to DND
            Customers also.
          </li>
          <li className="text-lg mt-2">
            Fixed 06 alphabets SENDER ID for both Transactional & Promotional
            SMS ( Example: INDSMS)
          </li>
          <li className="text-lg mt-2">API will not provided</li>
          <li className="text-lg mt-2">
            100% Delivery with Instant delivery report
          </li>
          <li className="text-lg mt-2">
            Any violation of norms by you or your user shall attract the
            necessary steps taken by the Operators & TRAI.
          </li>
          <li className="text-lg mt-2">18 % GST Tax Extra for any pack</li>
          <li className="text-lg mt-2">
            * Unlimited validity for all online SMS packs, But 01recharge is
            mandatory in a year to extend the validity
          </li>
        </div>
        <div className="laptop absolute w-full h-full"></div>
      </div>
    </section>
  );
};

export default Partner;
