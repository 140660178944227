import React from "react";
import Footer from "../components/Footer";

const About = () => {
  return (
    <>
      <div className="mt-28 mb-10 lg:px-20 px-8">
        <h1 className="about-text-bg lg:text-8xl text-4xl font-bold">
          About Us
        </h1>
        <h2 className="mt-2">
          We make it possible for everyone to communicate quickly and
          effectively.
        </h2>

        <h1 className="text-2xl  font-medium mt-8">About the Company</h1>
        <h2 className="mt-2">
          Both Surajenterprise.com and its parent firm were established in 2008.
          In 2001, the company's founders used SMS to transmit weather updates
          to customers, but they soon realised the service had many uses.
        </h2>
        <h2 className="mt-2">
          We offer application-to-person (A2P) messaging services to big and
          small companies, nonprofits, and private citizens. The company is
          present in more than 15 states in India, including the UAE and the
          USA.
        </h2>
        <h2 className="mt-2">
          The Complete Brochure (GUIDE) is available for download as a PDF here.
          - Click Here (ICON)
        </h2>

        <h1 className="text-2xl  font-medium mt-8">Our Strategy</h1>
        <h2 className="mt-2">
          We offer common platforms that any client can use to swiftly launch
          any SMS service. This covers SMS contests, SMS voting, SMS news
          services, SMS marketing campaigns, or SMS for CRM.
        </h2>

        <h1 className="text-2xl  font-medium mt-8">Our Values</h1>
        <h2 className="mt-2">Integrity</h2>
        <h2 className="mt-2">
          In all of our interactions, we are accountable, sincere, and fair.
        </h2>

        <h1 className="text-2xl  font-medium mt-8">Tenacity</h1>
        <h2 className="mt-2">
          We keep going through challenges knowing that better times are ahead.
        </h2>

        <h1 className="text-2xl  font-medium mt-8">Openness</h1>
        <h2 className="mt-2">
          We keep going through challenges knowing that better times are ahead.
        </h2>

        <h1 className="text-2xl  font-medium mt-8">Team Message,</h1>
        <h2 className="mt-2">
          Established in 2008 and our goal is to create IT answers for the
          medium and little scale enterprises. We likewise give administrations
          to independently employed people who wish to advance their business
          online for world market reach. 
        </h2>
        <h2 className="mt-2">
          Our essential center is to construct association with the customers to
          keep up the abnormal state of fulfillment and trust
        </h2>
        <h2 className="mt-2">
          Our organization offers tweaked programming improvement
          administrations for medium and little scale business, ERP Solution for
          Schools, College, Institute, and Universities. Customization of
          programming is helped with Prototyping Model. Under this displaying,
          the customer will undoubtedly solidify all his product necessities
          before the improvement begins. The necessities can be included all
          through the advancement cycle of the product. This training helps in
          offering custom-made programming that meets the customers fulfillment
          level. 
        </h2>
        <h2 className="mt-2">
          SurajEnterprise has confidence in synergistic development and gives
          broad administrations to industry in the innovation field since the
          season of its commencement.
        </h2>

        <h1 className="text-2xl  font-medium mt-8">Support Team Message,</h1>
        <h2 className="mt-2">
          For snappiest and most exact reaction, if you don't mind round out the
          shape beneath to the best of your capacity. The more data you give,
          the better shot we have of noting your inquiry or replicating your
          concern. You will get a provoke reaction amid business hours.
        </h2>
      </div>
      <Footer />
    </>
  );
};

export default About;
