import React from "react";
import BulkSms from "../SMS/BulkSms";
import BulkSmsTwo from "../SMS/BulkSmsTwo";
import Features from "../SMS/Features";
import Footer from "../components/Footer";
import FeaturePlan from "../SMS/FeaturePlan";
import Services from "../SMS/Services";
import Price from "../SMS/Price";
import {
  sms1,
  sms2,
  smsActivated,
  smsFeatures,
  smsPrice,
  smsN1,
  smsN2,
  smsNActivated,
  smsNFeatures,
  smsNPrice,
  smsG1,
  smsG2,
  smsGActivated,
  smsGFeatures,
  smsGPrice,
  smsSer,
} from "./webData";
import WebData2 from "./WebData2";

const WebDesign = () => {
  return (
    <>
      <div className="mt-28">
        <BulkSms data={sms1} />
        {/* <BulkSmsTwo data={sms2} /> */}
        <WebData2 data={sms2} />
        {/* <Features data={smsFeatures} />
        <FeaturePlan data={smsActivated} />
        <Price data={smsPrice} /> */}
      </div>

      <div className="mt-10">
        <BulkSms data={smsN1} />
        <WebData2 data={smsN2} />
        <Features data={smsNFeatures} />
        {/* <FeaturePlan data={smsNActivated} />
        <Price data={smsNPrice} /> */}
      </div>

      <div className="mt-10">
        <BulkSms data={smsG1} />
        <WebData2 data={smsG2} />
        <Features data={smsGFeatures} />
        {/* <Services data={smsSer} /> */}
        {/* <FeaturePlan data={smsGActivated} />
        <Price data={smsGPrice} /> */}
        <Footer />
      </div>
    </>
  );
};

export default WebDesign;
