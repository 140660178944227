import React from "react";
import Footer from "../components/Footer";
import BulkSms from "./BulkSms";
import Price from "./Price";
import Services from "./Services";
import { sms1, smsPrice, smsSer } from "./smsDataOtp";

const OtpSms = () => {
  return (
    <div>
      <div className="mt-28">
        <BulkSms data={sms1} />
        {/* <BulkSmsTwo data={sms2} /> */}
        <Services data={smsSer} />

        <Price data={smsPrice} />
        <Footer />
      </div>
    </div>
  );
};

export default OtpSms;
