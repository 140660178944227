import React from "react";
import Footer from "../components/Footer";

const Disclimar = () => {
  return (
    <>
      <div className="mt-28 mb-10 lg:mx-40 mx-8 lg:p-12 p-8 shadow-2xl">
        <h1 className="text-2xl  font-medium">Disclaimer</h1>
        <h2 className="mt-2">
          The information contained in this website is for general information
          purposes only. The information is provided by www.surajsolution.com a
          property of SURAJ SOLUTION. While we endeavor to keep the information
          up to date and correct, we make no representations of any kind,
          express or implied, about the completeness, accuracy, reliability,
          suitability or availability with respect to the website or the
          information, products, services, or related graphics contained on the
          website for any purpose. Any reliance you place on such information is
          therefore strictly at your own risk.
        </h2>
        <h2 className="mt-2">
          In no event will we be liable for any loss or damage including without
          limitation, indirect or consequential loss or damage, or any loss or
          damage whatsoever arising from loss of data or profits arising out of,
          or in connection with, the use of this website.
        </h2>
        <h2 className="mt-2">
          Through this website you are able to link to other websites which are
          not under the control of SURAJ SOLUTION. We have no control over the
          nature, content and availability of those sites. The inclusion of any
          links does not necessarily imply a recommendation or endorse the views
          expressed within them.
        </h2>
        <h2 className="mt-2">
          Every effort is made to keep the website up and running smoothly.
          However, SURAJ SOLUTION takes no responsibility for, and will not be
          liable for, the website being temporarily unavailable due to technical
          issues beyond our control.
        </h2>
<br></br>
        <div className="mt-2">
      <h2>
        <u><b>Cancellation Policy:</b></u>
        <br></br>
      </h2>
      <ol>
        <li>
          Final Order Confirmation: Once your order is confirmed, we cannot accept cancellations.
        </li>
        <li>
          Third-Party Commitment: As a third-party service provider, we allocate resources based on your confirmation, making last-minute changes difficult.
        </li>
        <li>
          Dedicated Resources: Your order triggers a commitment of time and effort from our team.
        </li>
        <li>
        Modifications: If you need to adjust your order, please contact us as soon as possible. We’ll do our best to accommodate your request.
        </li>
        <li>
         Efficiency Assurance: This policy is designed to ensure smooth operations and high-quality service for all clients.
        </li>
        <li>
          Thank You for Your Understanding: We appreciate your support and understanding of our cancellation policy.
        </li>
      </ol>
    </div>
  

        
      </div>
      <Footer />
    </>
  );
};

export default Disclimar;
