import React from "react";

const FeaturePlan = ({ data }) => {
  return (
    <div className="feature-plan  md:py-5 my-3">
      <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 justify-center items-center   text-lg gap-10 flex-col py-10 text-dark">
        {data?.map((data) => {
          return (
            <div className="flex justify-center items-center gap-3">
              <i class="fa-brands fa-creative-commons-sampling"></i>
              <p>{data.name}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FeaturePlan;
