import img1 from "../assets/sms/img1.jpg";
import img2 from "../assets/sms/img2.jpg";
import img3 from "../assets/sms/img3.jpg";

export const sms1 = [
  {
    id: 1,
    main: "IVR SERVICES    ",
    img: img1,
    name: "TOLL FREE NUMBER SERVICES",
    s1: " Your professionalism will astonish your customers.",
    s2: "The importance of keeping in touch with customers has risen as a result of greater awareness. Every firm strives to keep a relationship with customers in order to thrive. Customers are the initial point of contact when notifying them about new offerings, promotions, discounts, or a forthcoming event.",
    s3: "One of the newest methods of communicating with customers is the interactive voice response system, or IVR. These programmes offer a pre-recorded voice that converses with clients and responds to their inquiries. This automated system can also contact the client and direct them to the appropriate department to handle their inquiries. Also, it will assist in obtaining client data that may later prove useful in building a database. Toll-Free numbers are those with a unique three-digit code that you can call for free from a landline. With this, a caller can make an unlimited number of free calls.",
  },
];

// export const smsSer1 = [
//   {
//     id: 1,
//     head: " Using free email services has drawbacks   ",
//     tail1: "The email you sent might end up in the spam or archive folder.",
//     tail2: "	Customers typically disregard or delete such emails.",
//     tail3: "	Low conversion rates as a result of fewer customer responses.",
//     tail4: "	Get the client's consent",
//     tail5: "	After permission, continue.",
//   },
// ];
export const smsSer2 = [
  {
    title: "  Advantages of Toll-free Numbers",
  },
  {
    name: "⦁	The client sees professionalism displayed, and a positive impression is made.",
  },
  {
    name: "⦁	One of the simplest ways to communicate with clients in any language while using less staff is through this method.",
  },
  {
    name: "	⦁	Before providing cash on delivery orders, IVR systems can be used to check the customer's information. Time, money, and human resources are all saved.",
  },
  {
    name: "	⦁	Also, customers can check the status of their ordered products online.",
  },
  {
    name: "	⦁	Due to the need for fewer labour, it is less expensive.",
  },
  {
    name: "⦁	Suraj Enterprise offers 'text-enabled' Toll-free lines so that clients may send you messages, and you can respond to them in the same way. With our IVR system and Toll-Free number, you may use all the functions, and no call from one of your clients will go unanswered.	",
  },
];

export const sms2 = [
  {
    title:
      "The following are the benefits of using a professional service for your business: ",
  },
  {
    name: "⦁	Possessing a formal business email demonstrates your professionalism and commitment to your job.",
  },
  {
    name: "⦁	Compared to other email providers, your email is recognised sooner.",
  },
  {
    name: "⦁	A competent company mail service makes a positive impression on the client when sending mail.",
  },
  {
    name: "⦁	Due to the numerous security safeguards that professional business email offers, you are protected from malware, spam, and internet hacking assaults.",
  },
  {
    name: "⦁	With us, you can access your email from anywhere via webmail, a mobile device, etc., making it simple to reach and read.",
  },

  {
    p1: "Hence, let everyone know that you exist. Establish your brand, convey professionalism, and accomplish your business objectives. To learn more about our new programmes, which are inexpensive and adaptable for every business, get in touch with us. ",
  },
];

export const smsFeatures = [
  {
    heading: "1800 NUMBER SERIES",
    para: " We offer number ranges beginning at 1800 to accommodate consumer preferences and monitor marketing return on investment.",
    icons: "fa-solid fa-people-roof",
  },
  {
    heading: "SPECIFIC VOICE",
    para: "You can alter the voice to welcome your clients.",
    icons: "fa-solid fa-calendar-minus",
  },
  {
    heading: "SECRET CHARGE",
    para: "Ha. ha. Our welcoming panel can be used by anyone, even a non-technical person...",
    icons: "fa-solid fa-id-card",
  },
  {
    heading: "REVIEWS FROM USERS",
    para: "Our toll-free numbers make it easier to collect user feedback.",
    icons: "fa-solid fa-comments",
  },
  {
    heading: "ENDLESS CHANNEL",
    para: "Our infinite channels, which include call centres, conference apps, calling card apps, etc., make it simple for customers to call. ",
    icons: "fa-solid fa-reply-all",
  },
  {
    heading: "GRATIS CALLS  ",
    para: "A web-based panel can be used to view all real-time reports. We offer a web-based tool through which clients can see a detailed summary of their scheduled SMS. ",
    icons: "fa-solid fa-truck-fast",
  },
  {
    heading: "COMPLETE CALL REPORTING",
    para: " All calls are monitored, logged, and examined in order to provide an updated report.",
    icons: "fa-solid fa-clock",
  },
  {
    heading: "CALLERS ON THE BLACKLIST/WHITELIST ",
    para: "You can distinguish between desired and undesirable subscribers using this list. ",
    icons: "fa-solid fa-headset",
  },
  {
    heading: "48 HOUR ACTIVATION TIME.  ",
    para: " A web-based panel can be used to view all real-time reports. We offer a web-based tool through which clients can see a detailed summary of their scheduled SMS.    ",
    icons: "fa-solid fa-envelope-open-text",
  },
];

export const smsActivated = [
  {
    name: "Activation time instant",
  },
  {
    name: "server time 9Am to 9pm ",
  },
  {
    name: "Delivery ratio 100%",
  },
  {
    name: "File format(Excel)",
  },
  {
    name: "Delivery all numbers",
  },
  {
    name: "validity unlimited",
  },
  {
    name: "web panel",
  },
  {
    name: "delivery report",
  },
];

export const smsPrice = [
  {
    pack: "Priority Mail",
    price: "₹	3,500",
  },
  {
    pack: "Amazon SES",
    price: "₹	2,000",
  },
  {
    pack: "Mail Grid",
    price: "₹	2,500",
  },
  {
    pack: "G Suite",
    price: "₹	3,000",
  },
  {
    pack: "Microsoft Office 365",
    price: "₹	2,000",
  },
  {
    pack: "ZOHO Mail",
    price: "₹	2,000",
  },
];
// TWO*************************************************
export const smsN1 = [
  {
    id: 1,
    main: "IVR SERVICE PROVIDER",
    img: img2,
    name: "Never ignore a call from a client.",
    s1: "For the best Interactive Voice Response (IVR) system, get in touch with Suraj Enterprise. We assist you in internationalising your business. Our IVR system provides a dial-pad so that calls may be identified, segmented, and routed to the proper department.",
  },
];

export const smsN2 = [
  { title: "Advantages of IVR" },
  {
    name: "	⦁	You can use it to record personalised messages that invite customers to call your business. ",
  },
  {
    name: "⦁	It conveys professionalism to customers and builds positive perceptions of your business.",
  },
  {
    name: "⦁	Gather Data – The IVR gathers data from callers and routes calls to the desired extension or department.",
  },
  {
    name: "⦁	IVR technologies for automated customer service enable clients to resolve many of their issues without speaking to an employee.",
  },
  {
    name: "⦁	Send the caller to the proper department — IVR directs the caller to the proper group or division.",
  },
  {
    name: "⦁	High call volumes are easily handled- During peak hours, there are a lot of calls. Without losing a single call, IVR manages every call and routes it to the proper department. The IVR system will instruct callers to hold if the desired individual is already on another call.",
  },
  {
    name: "⦁	System IVR by Design Host sets up and assigns a sophisticated and reasonably priced virtual number system for your business that aids in the expansion of your enterprise. We offer an IVR system with the following features:-",
  },
  {
    name: "⦁	Voice Mails",
  },
  {
    name: "⦁	Leads notifications",
  },
  {
    name: "⦁	facility for call recording",
  },
  {
    name: "⦁	welcome letters",
  },
  {
    name: "⦁	phone conferences",
  },
  {
    p1: "To learn more about our cost-effective options that will assist you in accomplishing your business objectives through an automated procedure, get in touch with our IVR team.",
  },
];

export const smsNFeatures = [
  {
    heading: "PERSONNEL GREETINGS",
    para: " Every time a consumer calls, they will be greeted professionally.",
    icons: "fa-solid fa-people-roof",
  },
  {
    heading: "IVR HOSTED SERVICE",
    para: "We also provide hosted IVR services, which employ technology to automatically answer calls without the need for an operator.",
    icons: "fa-solid fa-calendar-minus",
  },
  {
    heading: "ALERTS VIA SMS FOR CALLS",
    para: " Our SMS notifications on-call tool will let you know if you've missed any customer calls. ",
    icons: "fa-solid fa-id-card",
  },
  {
    heading: "PERSONAL PHONE (OPTIONAL)",
    para: "You may create a whole IVR system on your phone with the aid of our app, saving money on pricey hardware.",
    icons: "fa-solid fa-comments",
  },
  {
    heading: " Local telephones in your area",
    para: "Also, you can purchase local numbers for your region to draw customers.",
    icons: "fa-solid fa-reply-all",
  },
  {
    heading: "   ENDLESS CHANNEL ",
    para: "We make it simple for customers to access our endless channels, which include call centres, conference apps, calling card apps, etc.",
    icons: "fa-solid fa-truck-fast",
  },
  {
    heading: "REVIEWS FROM USERS",
    para: "We receive user feedback through our automated IVR phones. ",
    icons: "fa-solid fa-clock",
  },
  {
    heading: "   COMPLETE CALL REPORTING ",
    para: "With the help of the sophisticated software we developed, you can track every call you get. The programme we developed allows you to check the status of your calls as well.  ",
    icons: "fa-solid fa-headset",
  },
  {
    heading: "48 HOUR ACTIVATION TIME.     ",
    para: " It takes 48 hours for our IVR number system to become operational.       ",
    icons: "fa-solid fa-envelope-open-text",
  },
];

export const smsNActivated = [
  {
    name: "Activation time instant",
  },
  {
    name: "server time 9Am to 9pm ",
  },
  {
    name: "Delivery ratio 100%",
  },
  {
    name: "File format(Excel)",
  },
  {
    name: "Delivery all numbers",
  },
  {
    name: "validity unlimited",
  },
  {
    name: "web panel",
  },
  {
    name: "delivery report",
  },
];

export const smsNPrice = [
  {
    pack: "50,000 SMS",
    vol: "₹	13 Paise ",
    price: "₹	6,500",
  },
  {
    pack: "1,00,000 SMS",
    vol: "₹	12 Paise ",
    price: "₹	12,000",
  },
  {
    pack: "5,00,000 SMS",
    vol: "₹	11 Paise ",
    price: "₹	55,000",
  },
  {
    pack: "10,00,000 SMS",
    vol: "₹	10 Paise ",
    price: "₹	1,42,500",
  },
];
// THRee*************************************************
export const smsG1 = [
  {
    id: 1,
    main: "MISSED CALL ALERT ",
    img: img3,
    name: "   ",
    s1: " Using Suraj Enterprise as your partner in accomplishing your objectives, turn missed calls into possible leads. ",
    s2: "We understand how important clients are to any organisation. Every business relies on them to function. Thus, connecting with them is a necessary evil. Whether you are a new or seasoned customer, maintaining positive client relationships is essential. This demonstrates your commitment to your work, which impresses clients without a doubt. Our talented team is committed to offering the best services that will have a high success rate in a short amount of time.",
  },
];
export const smsSer = [
  {
    id: 1,
    head: "Services provided by Suraj Enterprise    ",
    tail1:
      "You can schedule SMS using the features and options available in the SMS panel.",
    tail2: "Unlimited SMS panel and expiry.    ",
    tail3:
      "Our SMS plans deliver messages faster than other bulk SMS service providers.    ",
    tail4:
      "SMS will also be sent via standard GSM if there is no sender ID.    ",
    tail5:
      "We also have bespoke plans along with pre-determined plans for businesses and startups big and small. ",
  },
];
export const smsG2 = [
  { title: "Why did you choose Suraj Enterprise?" },
  {
    name: "⦁	We give you the ability to examine and retrieve client data, which is advantageous for your company. ",
  },
  {
    name: "⦁	We also offer a graphical depiction of the data to further aid in your comprehension.",
  },
  {
    name: "⦁	As soon as the miss call alert system detects a missed call, a message is immediately sent to the client.",
  },
  {
    name: "⦁	Even during busy times, our system can manage any amount of missed calls.",
  },
  {
    p1: "You must work with an experienced partner or missed call alert service provider to receive the aforementioned benefits, and who could be a better partner than us?",
  },
  {
    p2: "After offering our best services to thousands of customers and learning about the most recent marketing trends, we have developed some cost-effective programmes that may be tailored to your needs. Regardless of the size, these plans are suitable for all businesses. Contact our Missed call alert Team to keep your relationship with your clients going strong.",
  },
];
//services
export const smsServices = [
  {
    id: 1,
    desc: "Get the best GSM SMS service plans at affordable prices with Suraj Enterprise, one of the largest B2B and B2C SMS providers in India.     ",
    contact:
      "Here our dedicated experts focus on the key factors. Try our service for the best results.     ",
  },
];

export const smsGFeatures = [
  {
    heading: "CALL MISS SERVICE",
    para: " We give consumers a dedicated number to call if their calls go unanswered.",
    icons: "fa-solid fa-people-roof",
  },
  {
    heading: "SECRET CHARGE",
    para: "Because we value openness, there are no hidden fees.",
    icons: "fa-solid fa-calendar-minus",
  },
  {
    heading: "CALL MISSING REPORTING",
    para: " Our robust software keeps track of every missed call and generates time-stamped results.",
    icons: "fa-solid fa-id-card",
  },
  {
    heading: "TIME OF ACTIVATION",
    para: "A web-based panel can be used to view all real-time reports. We offer a web-based tool through which clients can see a detailed summary of their scheduled SMS.",
    icons: "fa-solid fa-comments",
  },
  {
    heading: "ALERTS VIA SMS FOR CALLS ",
    para: "SMS alerts on call are provided by our robust software. ",
    icons: "fa-solid fa-reply-all",
  },
  {
    heading: "UNLIMITED MISS CALLS",
    para: "Our automatic system can keep track of an unlimited number of missed calls.",
    icons: "fa-solid fa-truck-fast",
  },
  // {
  //   heading: "REAL TIME REPORTS",
  //   para: " Our real-time reports are automatically updated as messages are processed. You can also track the progress of your SMS campaigns in real time. ",
  //   icons: "fa-solid fa-clock",
  // },
  // {
  //   heading: "CUSTOMER SUPPORT      ",
  //   para: " We offer 24/7 support via email, live chat and phone. Our professional team is known for solving all kinds of problems in the shortest possible time.       ",
  //   icons: "fa-solid fa-headset",
  // },
  // {
  //   heading: "TEXT/FLASH/ UNICODE MESSAGE      ",
  //   para: " All SMS types for individual accounts. Text flash unicode.       ",
  //   icons: "fa-solid fa-envelope-open-text",
  // },
];

export const smsGActivated = [
  {
    name: "Activation time instant",
  },
  {
    name: "server time 9Am to 9pm ",
  },
  {
    name: "Delivery ratio 100%",
  },
  {
    name: "File format(Excel)",
  },
  {
    name: "Delivery all numbers",
  },
  {
    name: "validity unlimited",
  },
  {
    name: "web panel",
  },
  {
    name: "delivery report",
  },
];

export const smsGPrice = [
  {
    pack: "50,000 SMS",
    vol: "₹	13 Paise ",
    price: "₹	6,500",
  },
  {
    pack: "1,00,000 SMS",
    vol: "₹	12 Paise ",
    price: "₹	12,000",
  },
  {
    pack: "5,00,000 SMS",
    vol: "₹	11 Paise ",
    price: "₹	55,000",
  },
  {
    pack: "10,00,000 SMS",
    vol: "₹	10 Paise ",
    price: "₹	1,42,500",
  },
];
