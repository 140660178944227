import React from "react";

const BulkSmsTwo = ({ data }) => {
  return (
    <>
      {data?.map((data) => {
        return (
          <div className="my-5 px-10 flex flex-col justify-center items-center ">
            <h2 className="text-3xl py-3 font-semibold ">Achieved</h2>
            <span className="self-start font-bold text-6xl quotation text-blue11">
              “
            </span>
            <h2 className="text-center ">{data.desc}</h2>
            <p>{data.contact}</p>
            <span className="self-end rotate-180 font-bold text-6xl quotation text-blue11">
              “
            </span>
          </div>
        );
      })}
    </>
  );
};

export default BulkSmsTwo;
