import img1 from "../assets/sms/img1.jpg";
import img2 from "../assets/sms/img2.jpg";
import img3 from "../assets/sms/img3.jpg";

export const sms1 = [
  {
    id: 1,
    main: "EMAIL MARKETING",
    img: img1,
    name: "Promotional Emails",
    s1: " For our best Promotional Email service, which will help you receive more high-value prospects, get in touch with Suraj Enterprise.",
    s2: "Every hour, around 122 billion emails are sent. This phrase sums it all up. The world of marketing is transforming thanks to email marketing. It has facilitated trade regardless of distance, duration, or cost. With this effective instrument, conducting business has gotten lot easier. Also, it serves as a link between a customer and a company. You can establish a tailored connection with customers using email or EDM (Electronic Data Mailers).",
  },
];

// export const smsSer1 = [
//   {
//     id: 1,
//     head: " Using free email services has drawbacks   ",
//     tail1: "The email you sent might end up in the spam or archive folder.",
//     tail2: "	Customers typically disregard or delete such emails.",
//     tail3: "	Low conversion rates as a result of fewer customer responses.",
//     tail4: "	Get the client's consent",
//     tail5: "	After permission, continue.",
//   },
// ];
export const smsSer2 = [
  {
    title:
      "  Why should you use promotional emails as a strategy for marketing?",
  },
  {
    name: "⦁	Millions of your customers can be informed with just one click about attractive deals, discounts, new items, or campaigns. Everyone may access the mail anytime, anywhere, thanks to the growing popularity of mobile devices. So, it only takes a few seconds.",
  },
  {
    name: "⦁	Promotional emails are created with brand identity in mind to raise awareness of the company.",
  },
  {
    name: "	⦁	It aids in keeping a committed database.",
  },
  { name: "⦁    It is the most affordable and quick form of communication." },
  {
    name: "⦁    Given that emails may be accessed from mobile devices, tabs, and other portable devices, there is a higher likelihood that clients will read them as soon as they arrive.",
  },
  {
    name: "⦁    Emails can be accessed by entering a password and email address. As a result, it is a secure and trustworthy method of communicating with the client.",
  },
  {
    p1: "Suraj Enterprise staff is responsive enough to use promotional emails to increase traffic. With us, you can schedule your email and can alert your client about the mail by SMS irrespective of any vertical. Also, it will assist you provide a positive picture to the client. Email We send SMS messages with IVR numbers included, and clients call us directly as a result. Also, we have developed a few practical and affordable programmes that will undoubtedly benefit you the most.",
  },
];

export const sms2 = [
  {
    title:
      "The following are the benefits of using a professional service for your business: ",
  },
  {
    name: "⦁	Possessing a formal business email demonstrates your professionalism and commitment to your job.",
  },
  {
    name: "⦁	Compared to other email providers, your email is recognised sooner.",
  },
  {
    name: "⦁	A competent company mail service makes a positive impression on the client when sending mail.",
  },
  {
    name: "⦁	Due to the numerous security safeguards that professional business email offers, you are protected from malware, spam, and internet hacking assaults.",
  },
  {
    name: "⦁	With us, you can access your email from anywhere via webmail, a mobile device, etc., making it simple to reach and read.",
  },

  {
    p1: "Hence, let everyone know that you exist. Establish your brand, convey professionalism, and accomplish your business objectives. To learn more about our new programmes, which are inexpensive and adaptable for every business, get in touch with us. ",
  },
];

export const smsFeatures = [
  {
    heading: "MAIL PANEL",
    para: " Our original designs will give your website a finished, appealing look.",
    icons: "fa-solid fa-people-roof",
  },
  {
    heading: "SET UP EMAIL",
    para: "Regardless of the business vertical to which you belong, you can plan your emails and inform your clients of the most recent discounts or offers.",
    icons: "fa-solid fa-calendar-minus",
  },
  {
    heading: "CONDUCT A CONTACT LIST",
    para: "We will give you access to the list of email addresses that have agreed to receive communications from your business.",
    icons: "fa-solid fa-id-card",
  },
  {
    heading: "OPTIMIZE EMAIL (CTR/CPI)",
    para: "The CTR/CPI ratio allows us to determine how many people actually received the email that was sent.",
    icons: "fa-solid fa-comments",
  },
  {
    heading: "USE YOUR EXISTING TEMPLATE  ",
    para: "If you're okay with it, you can also utilise your current template. ",
    icons: "fa-solid fa-reply-all",
  },
  {
    heading: "PERSONALIZING EMAIL     ",
    para: "Your email can be customised based on your company's needs. ",
    icons: "fa-solid fa-truck-fast",
  },
  {
    heading: "PERFECTLY RESPONSIVE UI     ",
    para: "Our email designs are completely responsive and perfectly scale to any device. ",
    icons: "fa-solid fa-clock",
  },
  {
    heading: "GRATIS SUPPORT  ",
    para: "Easy-to-use parameters such as HTTP-API, XML, SDK, and Web Plugins are accessible with free developer support. ",
    icons: "fa-solid fa-headset",
  },
];

export const smsActivated = [
  {
    name: "Activation time instant",
  },
  {
    name: "server time 9Am to 9pm ",
  },
  {
    name: "Delivery ratio 100%",
  },
  {
    name: "File format(Excel)",
  },
  {
    name: "Delivery all numbers",
  },
  {
    name: "validity unlimited",
  },
  {
    name: "web panel",
  },
  {
    name: "delivery report",
  },
];

export const smsPrice = [
  {
    pack: "1,00,000",
    vol: "₹	0.03 Paise ",
    price: "₹	3,000",
  },
  {
    pack: "5,00,000",
    vol: "₹	0.028 Paise ",
    price: "₹	14,000",
  },
  {
    pack: "10,00,000",
    vol: "₹	0.025 Paise",
    price: "₹	25,000",
  },
  {
    pack: "15,00,000",
    vol: "₹	0.023 Paise ",
    price: "₹	34,500",
  },
  {
    pack: "20,00,000",
    vol: "₹	0.020 Paise ",
    price: "₹	40,000",
  },
];
// TWO*************************************************
export const smsN1 = [
  {
    id: 1,
    main: "DYNAMIC WEBSITE",
    img: img2,
    name: "DYNAMIC WEBSITE",
    s1: "Give your website a vibrant, highly engaging look with flawless design that incorporates all the features and functionalities needed for it to function at its best. Suraj Enterprise offers top-notch website designs with features that will draw customers in addition to persuading them to purchase your goods and services.",
    s2: "As we have been in this business for a long time, we are committed to providing competent and cutting-edge web development services that satisfy customers' needs. Our customers return to us because of our commitment to quality and quality work. In addition to other professional software, our highly qualified crew is a great at using Dreamweaver, Illustrator, Photoshop, and Flash. They are unmatched in their ability to work with HTML, CSS, JavaScript, etc.  ",
  },
];

export const smsN2 = [
  { title: "Offerings from Suraj Enterprise " },
  {
    name: "⦁	We create SEO-friendly websites that are simple for Google to monitor, which boosts traffic. ",
  },
  {
    name: "⦁	Eco-friendly title, Meta description, and Google Web Master Verification are present on every page of our website.",
  },
  {
    name: "⦁	You will receive free technical assistance from a member of our team for two months to help you with any website-related problems.",
  },
  {
    name: "⦁	To help you stay within your budget, we offer a free Text logo for your website.",
  },
  {
    name: "⦁	All of our designs are fully responsive.",
  },
  {
    name: "⦁	For a year, we offer a free Linux hosting server.",
  },
  {
    name: "⦁	All of the options on our websites can be changed. Future modifications can be made quickly and easily.",
  },
];

export const smsNFeatures = [
  {
    heading: "DIFFERENT WEBSITE",
    para: " Our dynamic website generates so much interest since it is so creative and appealing.",
    icons: "fa-solid fa-people-roof",
  },
  {
    heading: "FULLY PERSONALIZABLE",
    para: "Our websites can be completely customised to meet your needs.",
    icons: "fa-solid fa-calendar-minus",
  },
  {
    heading: "ENTIRELY RESPONSIVE DESIGN",
    para: " Our websites are completely responsive and work well on all devices. ",
    icons: "fa-solid fa-id-card",
  },
  {
    heading: "SEO PERFECT",
    para: "We build websites that are Google-friendly and easy to track.",
    icons: "fa-solid fa-comments",
  },
  {
    heading: "FREE SMALL SEO ",
    para: "We include SEO-friendly Title, Meta, Description, and Google Web Master Verification on every page we develop. ",
    icons: "fa-solid fa-reply-all",
  },
  {
    heading: "TROUBLESHOT/ADMIN PANEL      ",
    para: "You can self-manage your website using our management panel. ",
    icons: "fa-solid fa-truck-fast",
  },
  {
    heading: "2 FREE MONTHS OF SUPPORT",
    para: " We offer free two months of online help to address all of your website-related concerns. ",
    icons: "fa-solid fa-clock",
  },
  {
    heading: "SHARED HOSTING FOR LINUX    ",
    para: "Also, we offer a free Linux hosting server for one user.  ",
    icons: "fa-solid fa-headset",
  },
  {
    heading: "TEXT/FLASH/ UNICODE MESSAGE      ",
    para: " All SMS types for individual accounts. Text flash unicode.       ",
    icons: "fa-solid fa-envelope-open-text",
  },
];

export const smsNActivated = [
  {
    name: "Activation time instant",
  },
  {
    name: "server time 9Am to 9pm ",
  },
  {
    name: "Delivery ratio 100%",
  },
  {
    name: "File format(Excel)",
  },
  {
    name: "Delivery all numbers",
  },
  {
    name: "validity unlimited",
  },
  {
    name: "web panel",
  },
  {
    name: "delivery report",
  },
];

export const smsNPrice = [
  {
    pack: "50,000 SMS",
    vol: "₹	13 Paise ",
    price: "₹	6,500",
  },
  {
    pack: "1,00,000 SMS",
    vol: "₹	12 Paise ",
    price: "₹	12,000",
  },
  {
    pack: "5,00,000 SMS",
    vol: "₹	11 Paise ",
    price: "₹	55,000",
  },
  {
    pack: "10,00,000 SMS",
    vol: "₹	10 Paise ",
    price: "₹	1,42,500",
  },
];
// THRee*************************************************
export const smsG1 = [
  {
    id: 1,
    main: "WORDPRESS WEBSITE  ",
    img: img3,
    name: "Join the list of satisfied customers by SurajEnterprise.    ",
    s1: " Several brand-new web design firms have entered the market with an expanding breadth. This has given the market a feeling of fierce competition. The game is lost by those who are not the best. ",
    s2: "A one-stop shop for top-notch Word Press development services, Suraj Enterprise is who we are. Our incredibly skilled team knows how to create a website that will compel visitors to return and purchase your goods and services. Customer is our focus",
    s3: "In our opinion, the first step in the designing process is involving the client. We can better comprehend their expectations by talking with them about and understanding the website's requirements. We record each debate topic so that we don't make any mistakes. To give an example of what",
  },
];
export const smsSer = [
  {
    id: 1,
    head: "Services provided by Suraj Enterprise    ",
    tail1:
      "You can schedule SMS using the features and options available in the SMS panel.",
    tail2: "Unlimited SMS panel and expiry.    ",
    tail3:
      "Our SMS plans deliver messages faster than other bulk SMS service providers.    ",
    tail4:
      "SMS will also be sent via standard GSM if there is no sender ID.    ",
    tail5:
      "We also have bespoke plans along with pre-determined plans for businesses and startups big and small. ",
  },
];
export const smsG2 = [
  { title: "Why we? " },
  {
    name: "⦁	To utilise our SEO panel, you don't need to be tech-savvy. It is simple to use for any layperson.  ",
  },
  {
    name: "⦁	The real-time reports are available as the messages are processed.",
  },
  {
    name: "⦁	We can transfer your website to a new server.",
  },
  {
    name: "⦁	Either a new e-commerce website can be built or the current one can be upgraded.",
  },
  {
    name: "⦁	Our websites usually rank at the top of search engine results.",
  },
  {
    name: "⦁	SEO friendly websites built by us are simply trackable by Google which assists in generating traffic.",
  },
  {
    name: "⦁	Our website has Google Web Master Certification, an eco-friendly title, and a meta description on every page.",
  },
  {
    p1: "We have developed a few adaptable programmes that are reasonable and suitable for all businesses, regardless of their size, keeping in mind the various requirements of various organisations. Prepare yourself to be found by millions of customers using our service.",
  },
];
//services
export const smsServices = [
  {
    id: 1,
    desc: "Get the best GSM SMS service plans at affordable prices with Suraj Enterprise, one of the largest B2B and B2C SMS providers in India.     ",
    contact:
      "Here our dedicated experts focus on the key factors. Try our service for the best results.     ",
  },
];

export const smsGFeatures = [
  {
    heading: "BASED ON INSTALLATION",
    para: " We provide WordPress installation, configuration, and running readiness on your preferred hosting provider.",
    icons: "fa-solid fa-people-roof",
  },
  {
    heading: "MOBILE RESPONSIVE VIEW",
    para: "We designed a page that is completely responsive and works well on all devices.",
    icons: "fa-solid fa-calendar-minus",
  },
  {
    heading: "SEO",
    para: " We develop pages that are Google-friendly and simple to track. ",
    icons: "fa-solid fa-id-card",
  },
  {
    heading: "PANEL OF CONTROL (HOSTING)",
    para: "Consequently, using a web-based interface, the panel aids in website management.",
    icons: "fa-solid fa-comments",
  },
  {
    heading: "Hosting Panel for Linux ",
    para: "This panel offers a Graphical user interface (GUI) and automated tools, and it is made to make hosting simpler.  ",
    icons: "fa-solid fa-reply-all",
  },
  {
    heading: "GROUP HOSTING     ",
    para: "Our shared hosting is inexpensive and offers limitless resources.",
    icons: "fa-solid fa-truck-fast",
  },
  // {
  //   heading: "REAL TIME REPORTS",
  //   para: " Our real-time reports are automatically updated as messages are processed. You can also track the progress of your SMS campaigns in real time. ",
  //   icons: "fa-solid fa-clock",
  // },
  // {
  //   heading: "CUSTOMER SUPPORT      ",
  //   para: " We offer 24/7 support via email, live chat and phone. Our professional team is known for solving all kinds of problems in the shortest possible time.       ",
  //   icons: "fa-solid fa-headset",
  // },
  // {
  //   heading: "TEXT/FLASH/ UNICODE MESSAGE      ",
  //   para: " All SMS types for individual accounts. Text flash unicode.       ",
  //   icons: "fa-solid fa-envelope-open-text",
  // },
];

export const smsGActivated = [
  {
    name: "Activation time instant",
  },
  {
    name: "server time 9Am to 9pm ",
  },
  {
    name: "Delivery ratio 100%",
  },
  {
    name: "File format(Excel)",
  },
  {
    name: "Delivery all numbers",
  },
  {
    name: "validity unlimited",
  },
  {
    name: "web panel",
  },
  {
    name: "delivery report",
  },
];

export const smsGPrice = [
  {
    pack: "50,000 SMS",
    vol: "₹	13 Paise ",
    price: "₹	6,500",
  },
  {
    pack: "1,00,000 SMS",
    vol: "₹	12 Paise ",
    price: "₹	12,000",
  },
  {
    pack: "5,00,000 SMS",
    vol: "₹	11 Paise ",
    price: "₹	55,000",
  },
  {
    pack: "10,00,000 SMS",
    vol: "₹	10 Paise ",
    price: "₹	1,42,500",
  },
];
