import React from "react";
import BulkSms from "./BulkSms";
import BulkSmsTwo from "./BulkSmsTwo";
import Features from "./Features";
import Footer from "../components/Footer";
import FeaturePlan from "./FeaturePlan";
import Services from "./Services";
import Price from "./Price";
import {
  sms1,
  sms2,
  smsActivated,
  smsFeatures,
  smsPrice,
  smsN1,
  smsN2,
  smsNActivated,
  smsNFeatures,
  smsNPrice,
  smsG1,
  smsG2,
  smsGActivated,
  smsGFeatures,
  smsGPrice,
  smsSer,
} from "./smsData";
const PromotionalSms = () => {
  return (
    <>
      <div className="mt-28">
        <BulkSms data={sms1} />
        <BulkSmsTwo data={sms2} />
        <Features data={smsFeatures} />
        <FeaturePlan data={smsActivated} />
        <Price data={smsPrice} />
      </div>

      <div className="mt-10">
        <BulkSms data={smsN1} />
        <BulkSmsTwo data={smsN2} />
        <Features data={smsNFeatures} />
        <FeaturePlan data={smsNActivated} />
        <Price data={smsNPrice} />
      </div>
      <div className="mt-10">
        <BulkSms data={smsG1} />
        <BulkSmsTwo data={smsG2} />
        <Features data={smsGFeatures} />
        <Services data={smsSer} />
        <FeaturePlan data={smsGActivated} />
        <Price data={smsGPrice} />
        <Footer />
      </div>
    </>
  );
};

export default PromotionalSms;
