import React from "react";

const BulkSms = ({ data }) => {
  return (
    <>
      {data?.map((data) => {
        return (
          <>
            <h1 className="text-center my-3 text-2xl font-bold ">
              {data.main}
            </h1>
            <div className="grid lg:grid-cols-2 grid-cols-1  bg-lightblue11 ">
              <img src={data.img} alt="bulk" />
              <div className="flex flex-col gap-3 justify-center md:px-16 md:p-5 p-4 py-7">
                <h2 className="text-2xl font-medium">{data.name}</h2>
                <p className="text-gray11 text-justify">{data.s1}</p>
                <p className="text-gray11 text-justify">{data.s2}</p>
                <p className="text-gray11 text-justify">{data?.s3}</p>
                <p className="text-gray11 text-justify">{data?.s4}</p>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default BulkSms;
