import React from "react";
import BulkSms from "../SMS/BulkSms";
import BulkSmsTwo from "../SMS/BulkSmsTwo";
import Features from "../SMS/Features";
import Footer from "../components/Footer";
import FeaturePlan from "../SMS/FeaturePlan";
import Services from "../SMS/Services";
import Price from "../SMS/Price";
import {
  sms1,
  sms2,
  smsActivated,
  smsFeatures,
  smsPrice,
  smsN1,
  smsN2,
  smsNActivated,
  smsNFeatures,
  smsNPrice,
  smsG1,
  smsG2,
  smsGActivated,
  smsGFeatures,
  smsGPrice,
  smsSer,
  smsSer1,
  smsSer2,
} from "./emailData";
import WebData2 from "../WEB/WebData2";
import EmailPrice from "./EmailPrice";

const Email = () => {
  return (
    <div>
      <div className="mt-28">
        <BulkSms data={sms1} />
        {/* <Services data={smsSer1} /> */}
        {/* <Services data={smsSer2} /> */}
        {/* <BulkSmsTwo data={sms2} /> */}
        <WebData2 data={smsSer2} />
        <WebData2 data={sms2} />
        {/* <Features data={smsFeatures} /> */}
        {/* <FeaturePlan data={smsActivated} /> */}
        <EmailPrice data={smsPrice} />
        <Footer />
      </div>
    </div>
  );
};

export default Email;
