import React from "react";
import Footer from "../components/Footer";

const Terms = () => {
  return (
    <>
      <div className="mt-28 mb-10 lg:mx-40 mx-8 lg:p-12 p-8 shadow-2xl">
        <h1 className="text-2xl  font-medium">Terms and Condition</h1>
        <h1 className="text-2xl  font-medium mt-2">
          Welcome to Suraj Enterprise !
        </h1>
        <h2 className="mt-2">
          These Terms of Service are available on the Suraj Enterprise website
          (https://www.surajenterprise.com/ )
        </h2>
        <h2 className="mt-2">
          By accessing this website, you accept these terms. If you do not agree
          to all of the terms stated on this page, do not continue to use suraj
          enterprise. 
        </h2>
        <h2 className="mt-2">
          The following terms apply to these Terms of Use, Privacy Policy,
          Disclaimer and Agreement. "Customer", "You" and "Your" refer to you as
          you register on this website and comply with the Company's Terms and
          Conditions. "Company", "we", "us", "our" and "us" refer to our
          company. References to “party”, “party” or “us” refer to both the
          customer and us. In connection with the offer, acceptance and
          consideration of necessary payments, in accordance with applicable
          Dutch law, to expressly meet the needs of customers in relation to the
          Company's provision of certain services. Any use of the above terms or
          the singular, plural, capitalization and/or his/her other words shall
          be considered interchangeable and therefore refer to the same. 
        </h2>

        <h1 className="text-2xl  font-medium mt-8">COOKIES</h1>
        <h2 className="mt-2">
          We use cookies. By visiting Suraj Enterprise, you consent to the use
          of cookies in accordance with Suraj Enterprise's Privacy Policy. 
        </h2>
        <h2 className="mt-2">
          Most interactive websites use cookies to allow us to retrieve user
          information for each visit. Cookies are used on our Her website to
          enable the functionality of certain areas and to make it easier for
          visitors to access our Her website. Some affiliate/advertising
          partners may also use her cookies. 
        </h2>

        <h1 className="text-2xl  font-medium mt-8">LICENSE</h1>
        <h2 className="mt-2">
          Unless otherwise stated, Suraj Enterprise and/or its licensors own the
          intellectual property rights in all Suraj Enterprise Materials. All
          intellectual property rights are reserved. You may access from Suraj
          Enterprise for your personal use, subject to the restrictions set
          forth in these Terms of Use. 
        </h2>

        <h2 className="mt-8">Not To do:</h2>
        <li>Republish materials from SurajEnterprise</li>
        <li>Sell, rent or sublicense material from SurajEnterprise</li>
        <li>Duplicate, duplicate or copy SurajEnterprise materials</li>
        <li>Distribute content from SurajEnterprise </li>

        <h2 className="mt-2">
          Parts of this website offer an opportunity for users to post and
          exchange opinions and information in certain areas of the website.
          Suraj Enteprise does not filter, edit, publish or review Comments
          prior to their presence on the website. Comments do not reflect the
          views and opinions of Suraj Enteprise,its agents and/or affiliates.
          Comments reflect the views and opinions of the person who post their
          views and opinions. To the extent permitted by applicable laws, Suraj
          Enteprise shall not be liable for the Comments or for any liability,
          damages or expenses caused and/or suffered as a result of any use of
          and/or posting of and/or appearance of the Comments on this website
        </h2>
        <h2 className="mt-2">
          Portions of this Site offer users the opportunity to post and exchange
          opinions and information in certain areas of the Site. Suraj
          Enterprise does not filter, edit, post or review Comments before they
          are posted on the Site. Comments do not reflect the views or opinions
          of Suraj Enterprise, its agents and/or affiliates. Comments reflect
          the views and opinions of the person who posted them. to the maximum
          extent permitted by applicable law, suraj enterprises will not be
          responsible for any liability, damages or expenses arising from and/or
          resulting from the comments or the use and/or posting and/or display
          of the comments. Shall not. on this website.
        </h2>
        <h2 className="mt-2">
          Suraj Enterprise reserves the right to monitor and remove any comments
          that may be deemed inappropriate, offensive, or in violation of these
          Terms of Service. 
        </h2>
        <h2 className="mt-2">You warrant and represent that:</h2>
        <li>
          You are entitled to post comments on our website and have all
          necessary licenses and consents.
        </li>
        <li>
          Comments do not infringe any third party's intellectual property
          rights, including but not limited to copyrights, patents, or
          trademarks.
        </li>
        <li>
          Comments will not contain defamatory, defamatory, abusive, obscene, or
          illegal content that violates privacy.
        </li>
        <li>
          Comments will not be used to solicit or promote business or customs,
          or to suggest commercial activity or illegal activity. 
        </li>

        <h2 className="mt-2">
          You hereby grant Suraj Enterprise a non-exclusive license to use,
          reproduce, edit and permit others to use, reproduce and edit the
          Comments in any form, format or medium . 
        </h2>

        <h1 className="text-2xl  font-medium mt-8">
          HYPERLINKING TO OUR CONTENT
        </h1>
        <h2 className="mt-2">
          The following organizations may link to our website without prior
          written permission. 
        </h2>
        <li>governmental authorities;</li>
        <li>search engine;</li>
        <li>Press.</li>
        <li>
          The online directory provider may link to his website of ours as well
          as his website of other publicly traded companies. and
        </li>
        <li>
          System-wide accredited businesses that do not have a hyperlink to our
          website, except advertising non-profits, charity shopping malls, and
          charitable fundraising groups. 
        </li>
        <h2 className="mt-2">
          These organizations may link to our homepage, publications, or other
          site information only if the link:
        </h2>
        <h2 className="mt-2">(a) is in no way misleading; </h2>
        <h2 className="mt-2">
          (b) not falsely imply sponsorship, endorsement or approval of the
          Linking Party and its products and/or services;{" "}
        </h2>
        <h2 className="mt-2">
          (c) suitable for the context of his website to which it links; 
        </h2>
        <h2 className="mt-2">
          We can also additionally remember and approve different hyperlink
          requests from the subsequent kinds of organizations:
        </h2>
        <li>Popular consumer and/or business information sources.</li>
        <li>dot.com community site.</li>
        <li>Clubs or other groups representing charitable organizations.</li>
        <li>Distributors of online directories.</li>
        <li>Internet portal.</li>
        <li>Accounting, legal and consulting firms. and</li>
        <li>Educational institutions and professional associations. </li>
        <h2 className="mt-2">
          We will approve hyperlink requests from those companies if we
          determine that:
        </h2>
        <h2 className="mt-2">
          (a) the hyperlink could now no longer make us appearance unfavorably
          to ourselves or to our authorized businesses; (b) the organisation
          does now no longer have any terrible information with us; (c) the
          advantage to us from the visibility of the link compensates the
          absence of Suraj Enterprise; and (d) the hyperlink is withinside the
          context of standard aid data.
        </h2>
        <h2 className="mt-2">
          These companies might also additionally hyperlink to our domestic web
          page as long as the hyperlink:
        </h2>
        <h2 className="mt-2">
          (a) isn't in any manner deceptive; (b) does now no longer falsely
          suggest sponsorship, endorsement or approval of the linking birthday
          birthday celebration and its merchandise or services; and (c) suits in
          the context of the linking birthday birthday celebration's webweb
          page.
        </h2>
        <h2 className="mt-2">
          If you're one of the companies indexed in paragraph 2 above and are
          inquisitive about linking to our website, you have to tell us through
          sending an e mail to Suraj Enterprise. Please consist of your name,
          your organisation name, touch data in addition to the URL of your
          webweb page, a listing of any URLs from that you intend to hyperlink
          to our Website, and a listing of the URLs on our webweb page to that
          you would really like to hyperlink. Wait 2-three weeks for a response.
        </h2>
        <h2 className="mt-2">
          Approved organizations may link to our website as follows:
        </h2>
        <li>Use our company name. again</li>
        <li>Use a linked Uniform Resource Locator. Again</li>
        <li>
          Use other descriptions of linked sites that are meaningful in the
          context and format of the linked site's content.
        </li>
        <li>
          Use of Suraj Enterprise logos or other artwork in links is not
          permitted without a trademark license agreement.  
        </li>

        <h1 className="text-2xl  font-medium mt-8">IFRAMES</h1>
        <h2 className="mt-2">
          Without our prior approval and written permission, you may not create
          frames around our web pages to alter the visual presentation or
          appearance of our site. 
        </h2>

        <h1 className="text-2xl  font-medium mt-8">CONTENT LIABILITY</h1>
        <h2 className="mt-2">
          We are not responsible for any content that appears on your website.
          You agree to defend and defend us against any allegations made on your
          website. You may not link to any website that infringes, infringes or
          advocates infringement or other violation of the Site or the rights of
          any third party. 
        </h2>

        <h1 className="text-2xl  font-medium mt-8">YOUR PRIVACY</h1>
        <h2 className="mt-2">Please read Privacy Policy</h2>

        <h1 className="text-2xl  font-medium mt-8">RESERVATION OF RIGHTS</h1>
        <h2 className="mt-2">
          We reserve the right to require you to remove any link to our website
          or any particular link. You agree to promptly remove any link to our
          website upon request. We also reserve the right to change these Terms
          of Use and its Linking Policy at any time. By continuing to link to
          our site, you agree to be bound by and abide by these linking terms. 
        </h2>

        <h1 className="text-2xl  font-medium mt-8">
          REMOVAL OF LINKS FROM OUR WEBSITE
        </h1>
        <h2 className="mt-2">
          If for any reason you find inappropriate links on the site, please
          contact us at any time to let us know. We will consider requests to
          remove links, but we have no obligation to do so or to respond
          directly to you. 
        </h2>
        <h2 className="mt-2">
          If for any reason you find inappropriate links on the site, please
          contact us at any time to let us know. We will consider requests to
          remove links, but we have no obligation to do so or to respond
          directly to you. 
        </h2>

        <h1 className="text-2xl  font-medium mt-8">DISCLAIMER</h1>
        <h2 className="mt-2">
          To the fullest extent permitted by applicable law, we exclude all
          representations, warranties and conditions regarding our website and
          your use of this website. This disclaimer does not include: 
        </h2>

        <li>
          limit or exclude our or your liability for death or personal injury;
        </li>
        <li>
          limit or exclude our or your liability for fraud or fraudulent
          misrepresentation;
        </li>
        <li>
          limit our or your liability in any manner not permitted by applicable
          law; again
        </li>
        <li>
          exclude our or your liability that cannot be excluded under applicable
          law; 
        </li>
        <h2 className="mt-2">
          LIMITATIONS AND PROHIBITIONS SET FORTH IN THIS SECTION AND ELSEWHERE
          IN THIS DISCLAIMER:
        </h2>
        <h2 className="mt-2">
          (a) subject to the preceding paragraph; and (b) control all liability
          arising under this disclaimer, including liability arising out of
          contract, tort and breach of statutory duty.
        </h2>
        <h2 className="mt-2">
          As long as the website and the information and services on the website
          are provided free of charge, we will not be liable for any loss or
          damage of any kind. 
        </h2>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
