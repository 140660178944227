import img1 from "../assets/sms/img1.jpg";
import img2 from "../assets/sms/img2.jpg";
import img3 from "../assets/sms/img3.jpg";

export const sms1 = [
  {
    id: 1,
    main: "Promotional SMS",
    img: img1,
    name: "PROMOTIONAL BULK SMS SERVICE",
    s1: "Promotional SMS is part of bulk SMS service that helps you to inform your customers around the world about promotions, campaigns, offers or announcements. It is one of the most efficient tools for any business to promote itself regardless of time of day or demographic in the world",
    s2: "A partner for improving your business, Suraj Enterprise lets you reach your customers in an instant. SMS can reach millions of customers directly with one click. To make things more economical, Bulk SMS has entered a price competition. Bulk SMS has become essential for businesses ready to expand and thrive across borders. It also bridges the gap between buyers and sellers. With the progress of import and export business, the trend of bulk SMS has reached its peak.",
  },
];

export const sms2 = [
  {
    id: 1,
    desc: "Suraj Enterprise, one of the leading B2B and B2C SMS providers in India, has developed the best promotional SMS plans that are pocket-friendly and customizable to your business needs. Efficient Gateway offers multiple features to help customize and schedule messages according to the time, region, and language of your choice. Our software allows you to send millions of messages faster than any other SMS service provider. Unlimited SMS panels and expiration dates. There is also an option to send SMS with a GSM number in case no Sender ID has been created. Other services include Transactional SMS, Voice SMS and OTP SMS.",
    contact: "Contact us to avail of our best services.",
  },
];

export const smsFeatures = [
  {
    heading: "EASY TO MANAGE",
    para: " Even if you are a tech novice, our SMS service is perfect for you. Our easy-to-use SMS service lets you send thousands of messages with just one click.",
    icons: "fa-solid fa-people-roof",
  },
  {
    heading: "SMS SCHEDULING",
    para: "You can easily schedule SMS and check the status via our developed SMS panel.",
    icons: "fa-solid fa-calendar-minus",
  },
  {
    heading: "SENDER ID & TEMPLATE",
    para: " We offer unlimited SMS panels and unlimited SMS validity, so you don't need to keep track of how many SMS have been sent or how many times the SMS panel has been used. ",
    icons: "fa-solid fa-id-card",
  },
  {
    heading: "SMS GATEWAY TIME 12HR",
    para: "We follow rules and regulations to keep you safe. According to TRAI's rules and regulations, the promotion has only 12 hours of server time.",
    icons: "fa-solid fa-comments",
  },
  {
    heading: "UNLIMITED VALIDITY ",
    para: " We offer unlimited SMS panels and unlimited SMS validity, so you don't need to keep track of how many SMS have been sent or how many times the SMS panel has been used.       ",
    icons: "fa-solid fa-reply-all",
  },
  {
    heading: "FAST DELIVERY      ",
    para: " Our powerful software provides lightning-fast delivery to all networks. You can send 2,000,000 + SMS with one click.       ",
    icons: "fa-solid fa-truck-fast",
  },
  {
    heading: "REAL TIME REPORTS",
    para: " Our real-time reports are automatically updated as messages are processed. You can also track the progress of your SMS campaigns in real time. ",
    icons: "fa-solid fa-clock",
  },
  {
    heading: "CUSTOMER SUPPORT      ",
    para: " We offer 24/7 support via email, live chat and phone. Our professional team is known for solving all kinds of problems in the shortest possible time.       ",
    icons: "fa-solid fa-headset",
  },
  {
    heading: "TEXT/FLASH/ UNICODE MESSAGE      ",
    para: " All SMS types for individual accounts. Text flash unicode.       ",
    icons: "fa-solid fa-envelope-open-text",
  },
];

export const smsActivated = [
  {
    name: "Activation time instant",
  },
  {
    name: "server time 9Am to 9pm ",
  },
  {
    name: "Delivery ratio 100%",
  },
  {
    name: "File format(Excel)",
  },
  {
    name: "Delivery all numbers",
  },
  {
    name: "validity unlimited",
  },
  {
    name: "web panel",
  },
  {
    name: "delivery report",
  },
];

export const smsPrice = [
  {
    pack: "50,000 SMS",
    vol: "₹	13 Paise ",
    price: "₹	6,500",
  },
  {
    pack: "1,00,000 SMS",
    vol: "₹	12 Paise ",
    price: "₹	12,000",
  },
  {
    pack: "5,00,000 SMS",
    vol: "₹	11 Paise ",
    price: "₹	55,000",
  },
  {
    pack: "10,00,000 SMS",
    vol: "₹	10 Paise ",
    price: "₹	1,42,500",
  },
];
// TWO*************************************************
export const smsN1 = [
  {
    id: 1,
    main: "PROMOTIONAL SMS NON DND    ",
    img: img2,
    name: "PROMOTIONAL SMS NON DND",
    s1: "A partner for improving your business, Suraj Enterprise allows you to reach your customers in an instant. SMS can reach millions of customers directly with one click. To make things more economical, Bulk SMS has entered a price competition. Bulk SMS has become essential for businesses ready to expand and thrive across borders.It also bridges the gap between buyers and sellers. With the progress of import and export business, the trend of bulk SMS has reached its peak.     ",
    s2: "Promotional SMS is part of bulk SMS service that helps you to inform your customers around the world about promotions, campaigns, offers or announcements. It is one of the most efficient tools for any business to promote itself regardless of time of day or demographic in the world.     ",
  },
];

export const smsN2 = [
  {
    id: 1,
    desc: "Suraj Enterprise, one of the leading B2B and B2C SMS providers in India, has developed the best promotional SMS plans that are pocket-friendly and customizable to your business needs. Efficient Gateway offers multiple features to help customize and schedule messages according to the time, region, and language of your choice. Our software allows you to send millions of messages faster than any other SMS service provider. Unlimited SMS panels and expiration dates. There is also an option to send SMS with a GSM number in case no Sender ID has been created. Other services include Transactional SMS, Voice SMS and OTP SMS.     ",
    contact: "Contact us to avail of our best services.    ",
  },
];

export const smsNFeatures = [
  {
    heading: "EASY TO MANAGE",
    para: " Even if you are a tech novice, our SMS service is perfect for you. Our easy-to-use SMS service lets you send thousands of messages with just one click.",
    icons: "fa-solid fa-people-roof",
  },
  {
    heading: "SMS SCHEDULING",
    para: "You can easily schedule SMS and check the status via our developed SMS panel.",
    icons: "fa-solid fa-calendar-minus",
  },
  {
    heading: "SENDER ID & TEMPLATE",
    para: " We offer unlimited SMS panels and unlimited SMS validity, so you don't need to keep track of how many SMS have been sent or how many times the SMS panel has been used. ",
    icons: "fa-solid fa-id-card",
  },
  {
    heading: "SMS GATEWAY TIME 12HR",
    para: "We follow rules and regulations to keep you safe. According to TRAI's rules and regulations, the promotion has only 12 hours of server time.",
    icons: "fa-solid fa-comments",
  },
  {
    heading: "UNLIMITED VALIDITY ",
    para: " We offer unlimited SMS panels and unlimited SMS validity, so you don't need to keep track of how many SMS have been sent or how many times the SMS panel has been used.       ",
    icons: "fa-solid fa-reply-all",
  },
  {
    heading: "FAST DELIVERY      ",
    para: " Our powerful software provides lightning-fast delivery to all networks. You can send 2,000,000 + SMS with one click.       ",
    icons: "fa-solid fa-truck-fast",
  },
  {
    heading: "REAL TIME REPORTS",
    para: " Our real-time reports are automatically updated as messages are processed. You can also track the progress of your SMS campaigns in real time. ",
    icons: "fa-solid fa-clock",
  },
  {
    heading: "CUSTOMER SUPPORT      ",
    para: " We offer 24/7 support via email, live chat and phone. Our professional team is known for solving all kinds of problems in the shortest possible time.       ",
    icons: "fa-solid fa-headset",
  },
  {
    heading: "TEXT/FLASH/ UNICODE MESSAGE      ",
    para: " All SMS types for individual accounts. Text flash unicode.       ",
    icons: "fa-solid fa-envelope-open-text",
  },
];

export const smsNActivated = [
  {
    name: "Activation time instant",
  },
  {
    name: "server time 9Am to 9pm ",
  },
  {
    name: "Delivery ratio 100%",
  },
  {
    name: "File format(Excel)",
  },
  {
    name: "Delivery all numbers",
  },
  {
    name: "validity unlimited",
  },
  {
    name: "web panel",
  },
  {
    name: "delivery report",
  },
];

export const smsNPrice = [
  {
    pack: "50,000 SMS",
    vol: "₹	13 Paise ",
    price: "₹	6,500",
  },
  {
    pack: "1,00,000 SMS",
    vol: "₹	12 Paise ",
    price: "₹	12,000",
  },
  {
    pack: "5,00,000 SMS",
    vol: "₹	11 Paise ",
    price: "₹	55,000",
  },
  {
    pack: "10,00,000 SMS",
    vol: "₹	10 Paise ",
    price: "₹	1,42,500",
  },
];
// THRee*************************************************
export const smsG1 = [
  {
    id: 1,
    main: "GSM SMS SERVICE    ",
    img: img3,
    name: "GSM SMS SERVICE    ",
    s1: " All ages are addicted to SMS services. This makes the service popular all over the world in a very good way. Even a simple mobile phone has an SMS function. From informal to formal chat, SMS services have made their mark everywhere. His unlimited or low-cost SMS plans are adding fuel to the fire. ",
    s2: "With the advent of SMS functionality and increased use of mobile phones, people can stay up to date with the latest campaigns, promotions, offers, discounts, and announcements. It helps companies and brands to promote their products and services worldwide. Instantly update millions of potential and existing customers with just one click.  ",
  },
];
export const smsSer = [
  {
    id: 1,
    head: "Services provided by Suraj Enterprise    ",
    tail1:
      "You can schedule SMS using the features and options available in the SMS panel.",
    tail2: "Unlimited SMS panel and expiry.    ",
    tail3:
      "Our SMS plans deliver messages faster than other bulk SMS service providers.    ",
    tail4:
      "SMS will also be sent via standard GSM if there is no sender ID.    ",
    tail5:
      "We also have bespoke plans along with pre-determined plans for businesses and startups big and small. ",
  },
];
export const smsG2 = [
  {
    id: 1,
    desc: "Get the best GSM SMS service plans at affordable prices with Suraj Enterprise, one of the largest B2B and B2C SMS providers in India.     ",
    contact:
      "Here our dedicated experts focus on the key factors. Try our service for the best results.     ",
  },
];
//services
export const smsServices = [
  {
    id: 1,
    desc: "Get the best GSM SMS service plans at affordable prices with Suraj Enterprise, one of the largest B2B and B2C SMS providers in India.     ",
    contact:
      "Here our dedicated experts focus on the key factors. Try our service for the best results.     ",
  },
];

export const smsGFeatures = [
  {
    heading: "EASY TO MANAGE",
    para: " Even if you are a tech novice, our SMS service is perfect for you. Our easy-to-use SMS service lets you send thousands of messages with just one click.",
    icons: "fa-solid fa-people-roof",
  },
  {
    heading: "SMS SCHEDULING",
    para: "You can easily schedule SMS and check the status via our developed SMS panel.",
    icons: "fa-solid fa-calendar-minus",
  },
  {
    heading: "SENDER ID & TEMPLATE",
    para: " We offer unlimited SMS panels and unlimited SMS validity, so you don't need to keep track of how many SMS have been sent or how many times the SMS panel has been used. ",
    icons: "fa-solid fa-id-card",
  },
  {
    heading: "SMS GATEWAY TIME 12HR",
    para: "We follow rules and regulations to keep you safe. According to TRAI's rules and regulations, the promotion has only 12 hours of server time.",
    icons: "fa-solid fa-comments",
  },
  {
    heading: "UNLIMITED VALIDITY ",
    para: " We offer unlimited SMS panels and unlimited SMS validity, so you don't need to keep track of how many SMS have been sent or how many times the SMS panel has been used.       ",
    icons: "fa-solid fa-reply-all",
  },
  {
    heading: "FAST DELIVERY      ",
    para: " Our powerful software provides lightning-fast delivery to all networks. You can send 2,000,000 + SMS with one click.       ",
    icons: "fa-solid fa-truck-fast",
  },
  {
    heading: "REAL TIME REPORTS",
    para: " Our real-time reports are automatically updated as messages are processed. You can also track the progress of your SMS campaigns in real time. ",
    icons: "fa-solid fa-clock",
  },
  {
    heading: "CUSTOMER SUPPORT      ",
    para: " We offer 24/7 support via email, live chat and phone. Our professional team is known for solving all kinds of problems in the shortest possible time.       ",
    icons: "fa-solid fa-headset",
  },
  {
    heading: "TEXT/FLASH/ UNICODE MESSAGE      ",
    para: " All SMS types for individual accounts. Text flash unicode.       ",
    icons: "fa-solid fa-envelope-open-text",
  },
];

export const smsGActivated = [
  {
    name: "Activation time instant",
  },
  {
    name: "server time 9Am to 9pm ",
  },
  {
    name: "Delivery ratio 100%",
  },
  {
    name: "File format(Excel)",
  },
  {
    name: "Delivery all numbers",
  },
  {
    name: "validity unlimited",
  },
  {
    name: "web panel",
  },
  {
    name: "delivery report",
  },
];

export const smsGPrice = [
  {
    pack: "50,000 SMS",
    vol: "₹	13 Paise ",
    price: "₹	6,500",
  },
  {
    pack: "1,00,000 SMS",
    vol: "₹	12 Paise ",
    price: "₹	12,000",
  },
  {
    pack: "5,00,000 SMS",
    vol: "₹	11 Paise ",
    price: "₹	55,000",
  },
  {
    pack: "10,00,000 SMS",
    vol: "₹	10 Paise ",
    price: "₹	1,42,500",
  },
];
