import React from "react";

const Features = ({ data }) => {
  return (
    <div className="text-white md:py-10 py-5 px-10 flex flex-col justify-center items-center gradient-blue  ">
      <h2 className="text-3xl pb-12 font-semibold ">Our Panel Features</h2>
      <div className="grid md:grid-cols-3 grid-cols-1 gap-5">
        {data?.map((item) => {
          return (
            <div className="flex flex-col gap-4">
              <div className="flex gap-5 items-center">
                <p className={`${item.icons} text-3xl`}></p>
                <p className="font-semibold">{item.heading}</p>
              </div>
              <p className="text-[#ccc]">{item.para}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Features;
