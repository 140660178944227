import React from "react";

const WebData2 = ({ data }) => {
  return (
    <div className="p-10   ">
      {data?.map((data) => {
        return (
          <div className="mt-4">
            <h1 className="text-3xl font-semibold ">{data.title}</h1>
            <h2 className="">{data.name}</h2>
            <p>{data.p1}</p>
            <p>{data.p2}</p>
          </div>
        );
      })}
    </div>
  );
};

export default WebData2;
