import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";

const EmailPrice = ({ data }) => {
  return (
    <TableContainer component={Paper} className="p-10">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className="bg-skyblue">
          <TableRow>
            <TableCell>E-MAIL PROVIDER</TableCell>
            <TableCell align="right">Price</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              // key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row">
                {row.pack}
              </TableCell>
              <TableCell align="right">{row.price}</TableCell>
              <TableCell align="right">
                <Link to="/payments" className="bg-blue11 text-white p-3">
                  Buy now
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <small>
        Above mentioned price is exclusive of Gst. 18% Gst Applicable.{" "}
      </small>
    </TableContainer>
  );
};

export default EmailPrice;
