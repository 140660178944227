import img1 from "../assets/sms/img4.jpg";
import img2 from "../assets/sms/img5.jpg";
import img3 from "../assets/sms/img6.jpg";

export const sms1 = [
  {
    id: 1,
    main: "Transactional SMS    ",
    img: img1,
    name: "TRANSACTIONAL SMS SERVICE",
    s1: "In addition to covering almost all aspects of marketing, including promotions, Bulk SMS also joins the transaction line. Transactional SMS is used to send a registered customer her OTP, information messages, booking and order notifications. For example, shipping, billing or shipping details for online products. Transactional SMS can be used by any business that sells products or services. B. Banks and financial institutions, insurance companies, credit card companies, registered educational institutions, airlines and railways (ticket and his PNR details).     ",
    s2: "Suraj Enterprise – India's leading B2B and B2C SMS provider designed the best and most affordable transactional SMS plans. Our transactional SMS gateway allows you to create as many message templates as you want from your account. You can also use the API to send transactional messages to your customers. Unlimited SMS charges and expiration. The delivery speed of our SMS plans is second to none. We also create automated SMS that take the heavy lifting out of sending messages on time and impress your customers.     ",
  },
];

export const sms2 = [
  {
    id: 1,
    desc: "We understand that every business has different needs. With that in mind, there are pre-determined and custom plan options. You can choose one based on your business needs and budget. Our goal is to build a healthy and long-term relationship between you and your customers. We are your partner in improving your business and achieving your business goals. Please hurry! Contact us to make your dream come true. ",
    contact: "Contact us to avail of our best services.",
  },
];

export const smsFeatures = [
  {
    heading: "EASY TO MANAGE",
    para: " It provides a one-click SMS service that allows you to send thousands of SMS without any technical knowledge required. ",
    icons: "fa-solid fa-people-roof",
  },
  {
    heading: "SMS SCHEDULING",
    para: "You can schedule SMS as per your need and check the status of our web panel    .",
    icons: "fa-solid fa-calendar-minus",
  },
  {
    heading: "SENDER ID & TEMPLATE",
    para: " Create multiple sender IDs and templates to make your brand stand out and access all your activities from one panel.     ",
    icons: "fa-solid fa-id-card",
  },
  {
    heading: "SMS GATEWAY TIME 12HR",
    para: "We can send all SMS except promotional SMS to registered users 24 hours a day.     ",
    icons: "fa-solid fa-comments",
  },
  {
    heading: "UNLIMITED VALIDITY ",
    para: " With lightning speed, you can send SMS to over 2 million customers in 1 minute.     ",
    icons: "fa-solid fa-reply-all",
  },
  {
    heading: "FAST DELIVERY      ",
    para: "With lightning speed, you can send SMS to over 2 million customers in 1 minute.     ",
    icons: "fa-solid fa-truck-fast",
  },
  {
    heading: "REAL TIME REPORTS",
    para: " Our system provides real-time updated reports while messages are being processed and can also track SMS campaigns in real time.     ",
    icons: "fa-solid fa-clock",
  },
  {
    heading: "CUSTOMER SUPPORT      ",
    para: " With 24/7 support, issues are resolved as soon as they arise, ensuring a smooth workflow.     ",
    icons: "fa-solid fa-headset",
  },
  {
    heading: "TEXT/FLASH/ UNICODE MESSAGE      ",
    para: " We use Text Flash Unicode feature to offer all kinds of SMS in one account.     ",
    icons: "fa-solid fa-envelope-open-text",
  },
];

export const smsActivated = [
  {
    name: "Activation time instant",
  },
  {
    name: "server time 9Am to 9pm ",
  },
  {
    name: "Delivery ratio 100%",
  },
  {
    name: "File format(Excel)",
  },
  {
    name: "Delivery all numbers",
  },
  {
    name: "validity unlimited",
  },
  {
    name: "web panel",
  },
  {
    name: "delivery report",
  },
];

export const smsPrice = [
  {
    pack: "50,000 SMS",
    vol: "₹	13 Paise ",
    price: "₹	6,500",
  },
  {
    pack: "1,00,000 SMS",
    vol: "₹	12 Paise ",
    price: "₹	12,000",
  },
  {
    pack: "5,00,000 SMS",
    vol: "₹	11 Paise ",
    price: "₹	55,000",
  },
  {
    pack: "10,00,000 SMS",
    vol: "₹	10 Paise ",
    price: "₹	1,42,500",
  },
];
// TWO*************************************************
export const smsN1 = [
  {
    id: 1,
    main: "NOTIFICATION SMS    ",
    img: img2,
    name: "NOTIFICATION SMS",
    s1: "SMS Service – An essential service in the world of telephony, the World Wide Web, and cell phones. Users can send messages of up to 160 characters to another device. Long messages will be split into two or more messages by him depending on the number of words. All mobile phones support text messaging. Notification SMS is an integral part of maintaining a healthy relationship between you and your customers. They are sent in response to transactions or events that occur elsewhere. For example, Nike gives her 50% off shoes.   ",
    s2: "A notification SMS will be sent to all registered customers. This can increase the number of repeat customers that generate many potential leads.It also helps keep conversations going on social networks. In short, this feature helps connect users with your brand and company. This is definitely a good sign for business.     ",
  },
];

export const smsN2 = [
  {
    id: 1,
    desc: "With Suraj Enterprise, you can take full advantage of this feature including: B. Sending updates to your customers, letting them know about sales, campaign plans, live events, and more.     ",
    contact: "Contact us to avail of our best services.    ",
  },
];

export const smsNFeatures = [
  {
    heading: "EASY TO MANAGE",
    para: "Our software automates SMS notifications that make your job easier.    ",
    icons: "fa-solid fa-people-roof",
  },
  {
    heading: "SMS SCHEDULING",
    para: "With the SMS panel, you can schedule SMS as you like and check the SMS status    .",
    icons: "fa-solid fa-calendar-minus",
  },
  {
    heading: "SENDER ID & TEMPLATE",
    para: " You can also create a single identity and template that allows you to stand out from your brand and manage everything from a web panel. ",
    icons: "fa-solid fa-id-card",
  },
  {
    heading: "GATEWAY TIME 24 HRS    ",
    para: "Non-promotional messages can be sent to registered users 24 hours a day.    ",
    icons: "fa-solid fa-comments",
  },
  {
    heading: "UNLIMITED VALIDITY ",
    para: " We offer unlimited validity of SMS and SMS panel to ensure smooth workflow. Our powerful software allows you to send over 2 million SMS per minute at lightning speed.    ",
    icons: "fa-solid fa-reply-all",
  },
  {
    heading: "FAST DELIVERY      ",
    para: " Provides real-time reporting. The system updates all reports according to the processed messages. Track the progress of your SMS campaigns in real time.    ",
    icons: "fa-solid fa-truck-fast",
  },
  {
    heading: "NOTIFY OWN CUSTOMERS    ",
    para: " Get 24*7*365 support via email, phone, and live chat to resolve all your issues, regardless of time and place.    ",
    icons: "fa-solid fa-clock",
  },
  {
    heading: "CUSTOMER SUPPORT      ",
    para: " We offer 24/7 support via email, live chat and phone. Our professional team is known for solving all kinds of problems in the shortest possible time.       ",
    icons: "fa-solid fa-headset",
  },
  {
    heading: "TEXT/FLASH/ UNICODE MESSAGE      ",
    para: " Text Flash Unicode feature allows you to send all kinds of SMS with a single account.     ",
    icons: "fa-solid fa-envelope-open-text",
  },
];

export const smsNActivated = [
  {
    name: "Activation time instant",
  },
  {
    name: "server time 9Am to 9pm ",
  },
  {
    name: "Delivery ratio 100%",
  },
  {
    name: "File format(Excel)",
  },
  {
    name: "Delivery all numbers",
  },
  {
    name: "validity unlimited",
  },
  {
    name: "web panel",
  },
  {
    name: "delivery report",
  },
];

export const smsNPrice = [
  {
    pack: "50,000 SMS",
    vol: "₹	13 Paise ",
    price: "₹	6,500",
  },
  {
    pack: "1,00,000 SMS",
    vol: "₹	12 Paise ",
    price: "₹	12,000",
  },
  {
    pack: "5,00,000 SMS",
    vol: "₹	11 Paise ",
    price: "₹	55,000",
  },
  {
    pack: "10,00,000 SMS",
    vol: "₹	10 Paise ",
    price: "₹	1,42,500",
  },
];
// THRee*************************************************
export const smsG1 = [
  {
    id: 1,
    main: "DYNAMIC SMS    ",
    img: img3,
    name: "DYNAMIC SMS    ",
    s1: " Bulk SMS is a trendy weapon to boost your marketing efforts. Used by companies and brands of all sizes. However, users can get frustrated when irrelevant messages appear on their devices. Dynamic SMS was created to avoid this embarrassing situation. This feature allows you to send specific messages to relevant groups across the country. Use the best and cheapest dynamic SMS service with Suraj Enterprise. We are one of the leading B2B and B2C SMS providers in India. Our plans are flexible and can be customized to your needs.    ",
    s2: "For your convenience, we automate the SMS process and save the data to an Excel file. This saves time and keeps you in touch with your customers. Unlimited SMS panels and effectiveness set us apart from our competitors. Provides real-time reports that update as messages are processed. Fastest SMS delivery is what we offer to help you achieve your business goals. With active 24/7 customer support, you can always have your issue resolved at any time. To avoid confusion, we offer all types of SMS in one account. Save yourself the hassle of typing different messages and sending them to different users with a fully checked and tested service.    ",
  },
];
export const smsSer = [
  {
    id: 1,
    head: "Why?    ",
    tail1:
      "Our software automates SMS notifications that make your job easier.      ",
    tail2:
      "It also provides real-time reporting as the system automatically updates all reports as messages are processed.    ",
    tail3: "You can also track SMS with real-time campaigns.      ",
    tail4: "Active 24*7 365 days customer support.      ",
    tail5:
      "Unlimited SMS panels and SMS validity. Super fast delivery of SMS. Send over 2 million SMS with one click.      ",
    tail6: "It offers all types of SMS in one account.       ",
    w1: "Our SMS plans are affordable enough to stay within your budget. We also offer customizable plans to meet your business needs. Our goal is to achieve your business goals by increasing sales and customer satisfaction with the best advertising opportunities. ",
    w2: "Try Notification SMS service on Suraj Enterprise and see how easy this feature is to use. ",
  },
];
export const smsG2 = [
  {
    id: 1,
    desc: "Bulk SMS service. Our long-standing customer base is a testament to our quality work and our reputation makes us among the best.Build healthy relationships between you and your clients and In order to finally bring you good business goals, please contact us.     ",
    contact: "  ",
  },
];
//services
export const smsServices = [
  {
    id: 1,
    desc: "Get the best GSM SMS service plans at affordable prices with Suraj Enterprise, one of the largest B2B and B2C SMS providers in India.     ",
    contact:
      "Here our dedicated experts focus on the key factors. Try our service for the best results.     ",
  },
];

export const smsGFeatures = [
  {
    heading: "EASY TO MANAGE",
    para: " Even if you are a tech novice, our SMS service is perfect for you. Our easy-to-use SMS service lets you send thousands of messages with just one click.     ",
    icons: "fa-solid fa-people-roof",
  },
  {
    heading: "SMS SCHEDULING",
    para: "You can easily schedule SMS and check the status via our developed SMS panel.     ",
    icons: "fa-solid fa-calendar-minus",
  },
  {
    heading: "SENDER ID & TEMPLATE",
    para: " We offer unlimited SMS panels and unlimited SMS validity, so you don't need to keep track of how many SMS have been sent or how many times the SMS panel has been used.     ",
    icons: "fa-solid fa-id-card",
  },
  {
    heading: "SMS GATEWAY TIME 12HR",
    para: "We follow rules and regulations to keep you safe. According to TRAI's rules and regulations, the promotion has only 12 hours of server time.     ",
  },
  {
    heading: "UNLIMITED VALIDITY ",
    para: " We offer unlimited SMS panels and unlimited SMS validity, so you don't need to keep track of how many SMS have been sent or how many times the SMS panel has been used.     ",
    icons: "fa-solid fa-reply-all",
  },
  {
    heading: "FAST DELIVERY      ",
    para: " Our powerful software provides lightning-fast delivery to all networks. You can send 2,000,000 + SMS with one click.     ",
    icons: "fa-solid fa-truck-fast",
  },
  {
    heading: "REAL TIME REPORTS",
    para: "Our real-time reports are automatically updated as messages are processed. You can also track the progress of your SMS campaigns in real time.     ",
    icons: "fa-solid fa-clock",
  },
  {
    heading: "CUSTOMER SUPPORT      ",
    para: "We offer 24/7 support via email, live chat and phone. Our professional team is known for solving all kinds of problems in the shortest possible time.     ",
    icons: "fa-solid fa-headset",
  },
  {
    heading: "TEXT/FLASH/ UNICODE MESSAGE      ",
    para: " All SMS types for individual accounts. Text flash unicode.     ",
    icons: "fa-solid fa-envelope-open-text",
  },
];

export const smsGActivated = [
  {
    name: "Activation time instant",
  },
  {
    name: "server time 9Am to 9pm ",
  },
  {
    name: "Delivery ratio 100%",
  },
  {
    name: "File format(Excel)",
  },
  {
    name: "Delivery all numbers",
  },
  {
    name: "validity unlimited",
  },
  {
    name: "web panel",
  },
  {
    name: "delivery report",
  },
];

export const smsGPrice = [
  {
    pack: "50,000 SMS",
    vol: "₹	10 Paise ",
    price: "₹	5,000",
  },
  {
    pack: "1,00,000 SMS",
    vol: "₹	9.5 Paise ",
    price: "₹	9,500",
  },
  {
    pack: "5,00,000 SMS",
    vol: "₹	9 Paise ",
    price: "₹	45,000",
  },
  {
    pack: "10,00,000 SMS",
    vol: "₹	8.75 Paise ",
    price: "₹	87,000",
  },
];
