import img1 from "../assets/sms/img1.jpg";
import img2 from "../assets/sms/img2.jpg";
import img3 from "../assets/sms/img3.jpg";

export const sms1 = [
  {
    id: 1,
    main: "WEBSITE DEVLOPMENT",
    img: img1,
    name: "E-COMMERCE Sites",
    s1: "For any e-commerce solutions, get in touch with Suraj Enterprise, one click one solution. We are the answer to growing online sales, options, and leads.",
    s2: "With their expertise, our specialists are dedicated to providing high-quality work that will be advantageous for your company. We recognise how valuable your time and money are. Thus, when designing and developing your e-commerce web, adhere to the following simple hierarchy: ",
  },
];

export const smsSer1 = [
  {
    id: 1,
    head: "    ",
    tail1: "Comprehend the needs of the client",
    tail2: "	Make a note of every essential detail.",
    tail3: "	Create a website design plan.   ",
    tail4: "	Get the client's consent",
    tail5: "	After permission, continue.",
  },
];
export const smsSer2 = [
  {
    title: "  Functionalities that we include:  ",
  },
  {
    name: "⦁	Customers can find things easily when they are organised. To make the buying experience simple for customers, we categorise them into various groups.",
  },
  {
    name: "	⦁	Quick check: Anytime you go online, your smartphone will show the most recent changes.",
  },
  {
    name: "	⦁	Our websites feature several payment channels, and payment, taxes, and delivery are all clearly stated. Customers' shopping is made easier by the website's disclosure of location-specific tax rates and shipping alternatives.",
  },
  {
    name: "	⦁	Add products: You can add or update any product, including the details, features, and photos.",
  },
  {
    name: "	⦁	Control stock management: It is possible to plan out and maintain control over your stock management.",
  },
  {
    name: "		⦁	Real-time reporting: Real-time reports provide you a thorough explanation of the specifics.",
  },
  {
    name: "		⦁  We develop SEO-friendly websites that are simple for Google to track",
  },
  {
    name: "		⦁	Our SEO-friendly websites are simple for Google to monitor, which aids in boosting traffic.",
  },
  {
    name: "		⦁	You receive free technical support from our staff member for six months, who will address any of your website-related problems.",
  },
  {
    name: "		⦁	We offer a free Text logo for your website so that you may stay on budget.",
  },
  {
    name: "		⦁	Don't wait, then. We're prepared to help you reach new heights of achievement. Take advantage of our innovative programmes, which can be tailored to suit the needs of any business regardless of size and are also reasonably priced.",
  },
];

export const sms2 = [
  { title: "Why are we ahead of our competitors? " },
  {
    name: "⦁	Our website is SEO friendly and easily trackable on Google. This is a good sign that website traffic is growing.",
  },
  {
    name: "⦁	Every page on our website has an eco-friendly title, meta description and Google webmaster verification.",
  },
  {
    name: "⦁	Get 2 months of free support from our technicians to fix all your website related issues.",
  },
  {
    name: "⦁	We provide free text logos for your website that work within your budget.",
  },
  {
    name: "⦁	The designs we create are 100% responsive.",
  },
  {
    name: "⦁	Free hosting server for 3 Months (DEMO). ",
  },
  {
    name: "⦁	Our website is fully customizable. Future changes can be easily made. ",
  },
  {
    p1: "We want every business to benefit from our exclusive web design. We have developed specific plans for pricing. ",
  },
  {
    p2: "what are you looking for Contact us and experience your company in the digital world",
  },
];

export const smsFeatures = [
  {
    heading: "Online shopping portal",
    para: " Our e-commerce portal is so alluring that it is sure to draw more traffic and produce positive outcomes.",
    icons: "fa-solid fa-people-roof",
  },
  {
    heading: "FULLY PERSONALIZABLE",
    para: "We thoroughly customise the websites we build based on the needs of our clients.",
    icons: "fa-solid fa-calendar-minus",
  },
  {
    heading: "ENTIRELY RESPONSIVE DESIGN",
    para: "Our websites are completely responsive and appropriately sized for all devices.",
    icons: "fa-solid fa-id-card",
  },
  {
    heading: "SEO PERFECT",
    para: "Our websites are Google-friendly and are optimised for search engines.",
    icons: "fa-solid fa-comments",
  },
  {
    heading: "FREE SMALL SEO ",
    para: "We include SEO-friendly Title, Meta, Description, and Google Web Master Verification on every page we develop. ",
    icons: "fa-solid fa-reply-all",
  },
  {
    heading: "TROUBLESHOT/ADMIN PANEL      ",
    para: "The control panel can be used to manage or control your website. ",
    icons: "fa-solid fa-truck-fast",
  },
  {
    heading: "2 FREE MONTHS OF SUPPORT",
    para: " You will receive free assistance for two months to help you with any issues or glitches.",
    icons: "fa-solid fa-clock",
  },
  {
    heading: "SHARED HOSTING FOR LINUX    ",
    para: "We give away a free Linux hosting server for a year.  ",
    icons: "fa-solid fa-headset",
  },
  //   {
  //     heading: "TEXT/FLASH/ UNICODE MESSAGE      ",
  //     para: " All SMS types for individual accounts. Text flash unicode.       ",
  //     icons: "fa-solid fa-envelope-open-text",
  //   },
];

export const smsActivated = [
  {
    name: "Activation time instant",
  },
  {
    name: "server time 9Am to 9pm ",
  },
  {
    name: "Delivery ratio 100%",
  },
  {
    name: "File format(Excel)",
  },
  {
    name: "Delivery all numbers",
  },
  {
    name: "validity unlimited",
  },
  {
    name: "web panel",
  },
  {
    name: "delivery report",
  },
];

export const smsPrice = [
  {
    pack: "50,000 SMS",
    vol: "₹	13 Paise ",
    price: "₹	6,500",
  },
  {
    pack: "1,00,000 SMS",
    vol: "₹	12 Paise ",
    price: "₹	12,000",
  },
  {
    pack: "5,00,000 SMS",
    vol: "₹	11 Paise ",
    price: "₹	55,000",
  },
  {
    pack: "10,00,000 SMS",
    vol: "₹	10 Paise ",
    price: "₹	1,42,500",
  },
];
// TWO*************************************************
export const smsN1 = [
  {
    id: 1,
    main: "DYNAMIC WEBSITE",
    img: img2,
    name: "DYNAMIC WEBSITE",
    s1: "Give your website a vibrant, highly engaging look with flawless design that incorporates all the features and functionalities needed for it to function at its best. Suraj Enterprise offers top-notch website designs with features that will draw customers in addition to persuading them to purchase your goods and services.",
    s2: "As we have been in this business for a long time, we are committed to providing competent and cutting-edge web development services that satisfy customers' needs. Our customers return to us because of our commitment to quality and quality work. In addition to other professional software, our highly qualified crew is a great at using Dreamweaver, Illustrator, Photoshop, and Flash. They are unmatched in their ability to work with HTML, CSS, JavaScript, etc.  ",
  },
];

export const smsN2 = [
  { title: "Offerings from Suraj Enterprise " },
  {
    name: "⦁	We create SEO-friendly websites that are simple for Google to monitor, which boosts traffic. ",
  },
  {
    name: "⦁	Eco-friendly title, Meta description, and Google Web Master Verification are present on every page of our website.",
  },
  {
    name: "⦁	You will receive free technical assistance from a member of our team for two months to help you with any website-related problems.",
  },
  {
    name: "⦁	To help you stay within your budget, we offer a free Text logo for your website.",
  },
  {
    name: "⦁	All of our designs are fully responsive.",
  },
  {
    name: "⦁	For a year, we offer a free Linux hosting server.",
  },
  {
    name: "⦁	All of the options on our websites can be changed. Future modifications can be made quickly and easily.",
  },
];

export const smsNFeatures = [
  {
    heading: "DIFFERENT WEBSITE",
    para: " Our dynamic website generates so much interest since it is so creative and appealing.",
    icons: "fa-solid fa-people-roof",
  },
  {
    heading: "FULLY PERSONALIZABLE",
    para: "Our websites can be completely customised to meet your needs.",
    icons: "fa-solid fa-calendar-minus",
  },
  {
    heading: "ENTIRELY RESPONSIVE DESIGN",
    para: " Our websites are completely responsive and work well on all devices. ",
    icons: "fa-solid fa-id-card",
  },
  {
    heading: "SEO PERFECT",
    para: "We build websites that are Google-friendly and easy to track.",
    icons: "fa-solid fa-comments",
  },
  {
    heading: "FREE SMALL SEO ",
    para: "We include SEO-friendly Title, Meta, Description, and Google Web Master Verification on every page we develop. ",
    icons: "fa-solid fa-reply-all",
  },
  {
    heading: "TROUBLESHOT/ADMIN PANEL      ",
    para: "You can self-manage your website using our management panel. ",
    icons: "fa-solid fa-truck-fast",
  },
  {
    heading: "2 FREE MONTHS OF SUPPORT",
    para: " We offer free two months of online help to address all of your website-related concerns. ",
    icons: "fa-solid fa-clock",
  },
  {
    heading: "SHARED HOSTING FOR LINUX    ",
    para: "Also, we offer a free Linux hosting server for one user.  ",
    icons: "fa-solid fa-headset",
  },
  {
    heading: "TEXT/FLASH/ UNICODE MESSAGE      ",
    para: " All SMS types for individual accounts. Text flash unicode.       ",
    icons: "fa-solid fa-envelope-open-text",
  },
];

export const smsNActivated = [
  {
    name: "Activation time instant",
  },
  {
    name: "server time 9Am to 9pm ",
  },
  {
    name: "Delivery ratio 100%",
  },
  {
    name: "File format(Excel)",
  },
  {
    name: "Delivery all numbers",
  },
  {
    name: "validity unlimited",
  },
  {
    name: "web panel",
  },
  {
    name: "delivery report",
  },
];

export const smsNPrice = [
  {
    pack: "50,000 SMS",
    vol: "₹	13 Paise ",
    price: "₹	6,500",
  },
  {
    pack: "1,00,000 SMS",
    vol: "₹	12 Paise ",
    price: "₹	12,000",
  },
  {
    pack: "5,00,000 SMS",
    vol: "₹	11 Paise ",
    price: "₹	55,000",
  },
  {
    pack: "10,00,000 SMS",
    vol: "₹	10 Paise ",
    price: "₹	1,42,500",
  },
];
// THRee*************************************************
export const smsG1 = [
  {
    id: 1,
    main: "WORDPRESS WEBSITE  ",
    img: img3,
    name: "Join the list of satisfied customers by SurajEnterprise.    ",
    s1: " Several brand-new web design firms have entered the market with an expanding breadth. This has given the market a feeling of fierce competition. The game is lost by those who are not the best. ",
    s2: "A one-stop shop for top-notch Word Press development services, Suraj Enterprise is who we are. Our incredibly skilled team knows how to create a website that will compel visitors to return and purchase your goods and services. Customer is our focus",
    s3: "In our opinion, the first step in the designing process is involving the client. We can better comprehend their expectations by talking with them about and understanding the website's requirements. We record each debate topic so that we don't make any mistakes. To give an example of what",
  },
];
export const smsSer = [
  {
    id: 1,
    head: "Services provided by Suraj Enterprise    ",
    tail1:
      "You can schedule SMS using the features and options available in the SMS panel.",
    tail2: "Unlimited SMS panel and expiry.    ",
    tail3:
      "Our SMS plans deliver messages faster than other bulk SMS service providers.    ",
    tail4:
      "SMS will also be sent via standard GSM if there is no sender ID.    ",
    tail5:
      "We also have bespoke plans along with pre-determined plans for businesses and startups big and small. ",
  },
];
export const smsG2 = [
  { title: "Why we? " },
  {
    name: "⦁	To utilise our SEO panel, you don't need to be tech-savvy. It is simple to use for any layperson.  ",
  },
  {
    name: "⦁	The real-time reports are available as the messages are processed.",
  },
  {
    name: "⦁	We can transfer your website to a new server.",
  },
  {
    name: "⦁	Either a new e-commerce website can be built or the current one can be upgraded.",
  },
  {
    name: "⦁	Our websites usually rank at the top of search engine results.",
  },
  {
    name: "⦁	SEO friendly websites built by us are simply trackable by Google which assists in generating traffic.",
  },
  {
    name: "⦁	Our website has Google Web Master Certification, an eco-friendly title, and a meta description on every page.",
  },
  {
    p1: "We have developed a few adaptable programmes that are reasonable and suitable for all businesses, regardless of their size, keeping in mind the various requirements of various organisations. Prepare yourself to be found by millions of customers using our service.",
  },
];
//services
export const smsServices = [
  {
    id: 1,
    desc: "Get the best GSM SMS service plans at affordable prices with Suraj Enterprise, one of the largest B2B and B2C SMS providers in India.     ",
    contact:
      "Here our dedicated experts focus on the key factors. Try our service for the best results.     ",
  },
];

export const smsGFeatures = [
  {
    heading: "BASED ON INSTALLATION",
    para: " We provide WordPress installation, configuration, and running readiness on your preferred hosting provider.",
    icons: "fa-solid fa-people-roof",
  },
  {
    heading: "MOBILE RESPONSIVE VIEW",
    para: "We designed a page that is completely responsive and works well on all devices.",
    icons: "fa-solid fa-calendar-minus",
  },
  {
    heading: "SEO",
    para: " We develop pages that are Google-friendly and simple to track. ",
    icons: "fa-solid fa-id-card",
  },
  {
    heading: "PANEL OF CONTROL (HOSTING)",
    para: "Consequently, using a web-based interface, the panel aids in website management.",
    icons: "fa-solid fa-comments",
  },
  {
    heading: "Hosting Panel for Linux ",
    para: "This panel offers a Graphical user interface (GUI) and automated tools, and it is made to make hosting simpler.  ",
    icons: "fa-solid fa-reply-all",
  },
  {
    heading: "GROUP HOSTING     ",
    para: "Our shared hosting is inexpensive and offers limitless resources.",
    icons: "fa-solid fa-truck-fast",
  },
  // {
  //   heading: "REAL TIME REPORTS",
  //   para: " Our real-time reports are automatically updated as messages are processed. You can also track the progress of your SMS campaigns in real time. ",
  //   icons: "fa-solid fa-clock",
  // },
  // {
  //   heading: "CUSTOMER SUPPORT      ",
  //   para: " We offer 24/7 support via email, live chat and phone. Our professional team is known for solving all kinds of problems in the shortest possible time.       ",
  //   icons: "fa-solid fa-headset",
  // },
  // {
  //   heading: "TEXT/FLASH/ UNICODE MESSAGE      ",
  //   para: " All SMS types for individual accounts. Text flash unicode.       ",
  //   icons: "fa-solid fa-envelope-open-text",
  // },
];

export const smsGActivated = [
  {
    name: "Activation time instant",
  },
  {
    name: "server time 9Am to 9pm ",
  },
  {
    name: "Delivery ratio 100%",
  },
  {
    name: "File format(Excel)",
  },
  {
    name: "Delivery all numbers",
  },
  {
    name: "validity unlimited",
  },
  {
    name: "web panel",
  },
  {
    name: "delivery report",
  },
];

export const smsGPrice = [
  {
    pack: "50,000 SMS",
    vol: "₹	13 Paise ",
    price: "₹	6,500",
  },
  {
    pack: "1,00,000 SMS",
    vol: "₹	12 Paise ",
    price: "₹	12,000",
  },
  {
    pack: "5,00,000 SMS",
    vol: "₹	11 Paise ",
    price: "₹	55,000",
  },
  {
    pack: "10,00,000 SMS",
    vol: "₹	10 Paise ",
    price: "₹	1,42,500",
  },
];
