import img1 from "../assets/sms/img7.jpg";
import img2 from "../assets/sms/img8.jpg";
import img3 from "../assets/sms/img9.jpg";

export const sms1 = [
  {
    id: 1,
    main: "Voice SMS    ",
    img: img1,
    name: "VOICE CALL (Answer)    ",
    s1: "Get credit for missed calls with SurajEnterprise. Suraj Enterprise is one of the leading B2B and B2C SMS service providers in India. Now you can send voice messages to as many people as you like and get credit for missed calls.      ",
    s2: " Credits are added to your account within 24 hours after the answered and missed calls report is generated. With a voice calling (answering) plan, you can enjoy the following benefits:  ",
  },
];

export const sms2 = [
  {
    id: 1,
    desc: "We understand that every business has different needs. With that in mind, there are pre-determined and custom plan options. You can choose one based on your business needs and budget. Our goal is to build a healthy and long-term relationship between you and your customers. We are your partner in improving your business and achieving your business goals. Please hurry! Contact us to make your dream come true. ",
    contact: "Contact us to avail of our best services.",
  },
];

export const smsFeatures = [
  {
    heading: "EASY TO MANAGE",
    para: "Even if you are a tech novice, our SMS service is perfect for you. Our easy-to-use SMS service lets you send thousands of messages with just one click.     ",
    icons: "fa-solid fa-people-roof",
  },
  {
    heading: "SMS SCHEDULING",
    para: "You can easily schedule SMS and check the status via our developed SMS panel.     ",
    icons: "fa-solid fa-calendar-minus",
  },
  {
    heading: "SENDER ID & TEMPLATE",
    para: "We offer unlimited SMS panels and unlimited SMS validity, so you don't need to keep track of how many SMS have been sent or how many times the SMS panel has been used.     ",
    icons: "fa-solid fa-id-card",
  },
  {
    heading: "SMS GATEWAY TIME 12HR",
    para: "We follow rules and regulations to keep you safe. According to TRAI's rules and regulations, the promotion has only 12 hours of server time.     ",
    icons: "fa-solid fa-comments",
  },
  {
    heading: "UNLIMITED VALIDITY ",
    para: "We offer unlimited SMS panels and unlimited SMS validity, so you don't need to keep track of how many SMS have been sent or how many times the SMS panel has been used.     ",
    icons: "fa-solid fa-reply-all",
  },
  {
    heading: "FAST DELIVERY      ",
    para: "Our powerful software provides lightning-fast delivery to all networks. You can send 2,000,000 + SMS with one click.     ",
    icons: "fa-solid fa-truck-fast",
  },
  {
    heading: "REAL TIME REPORTS",
    para: "Our real-time reports are automatically updated as messages are processed. You can also track the progress of your SMS campaigns in real time.     ",
    icons: "fa-solid fa-clock",
  },
  {
    heading: "CUSTOMER SUPPORT      ",
    para: "We offer 24/7 support via email, live chat and phone. Our professional team is known for solving all kinds of problems in the shortest possible time.     ",
    icons: "fa-solid fa-headset",
  },
  {
    heading: "TEXT/FLASH/ UNICODE MESSAGE      ",
    para: " All SMS types for individual accounts. Text flash unicode.    ",
    icons: "fa-solid fa-envelope-open-text",
  },
];

export const smsActivated = [
  {
    name: "Activation time instant",
  },
  {
    name: "server time 9Am to 9pm ",
  },
  {
    name: "Delivery ratio 100%",
  },
  {
    name: "File format(Excel)",
  },
  {
    name: "Delivery all numbers",
  },
  {
    name: "validity unlimited",
  },
  {
    name: "web panel",
  },
  {
    name: "delivery report",
  },
];

export const smsPrice = [
  {
    pack: "50,000 SMS",
    vol: "₹ 30 Paise ",
    price: "₹	7,500",
  },
  {
    pack: "1,00,000 SMS",
    vol: "₹	27 Paise ",
    price: "₹	13,500",
  },
  {
    pack: "5,00,000 SMS",
    vol: "₹	26.5 Paise ",
    price: "₹	26,500",
  },
  {
    pack: "10,00,000 SMS",
    vol: "₹	26 Paise ",
    price: "₹	1,30,000",
  },
];
export const smsSer = [
  {
    id: 1,
    head: "",
    tail1: "You can send voice messages in any language in the country.      ",
    tail2:
      "You can record your own messages and our team will send them to your customer      ",
    tail3: "Messages can be customized according to the instructions    ",
    tail4: "Messages can be easily scheduled from the SMS panel.    ",
    tail5:
      "Our web-based application provides complete reporting of scheduled SMS.      ",
    tail6:
      "With our active 24/7/365 customer support, any issue can be resolved in no time.    ",
    w1: "Custom APIs we build are integrated into your application to send alerts, notifications, confirmations, and more. Our messages are delivered faster than other service providers. You can create multiple branded sender IDs and templates. ",
    w2: "Having been in this industry for a long time, we know the value of your time and money. To save you both, we've developed specific plans that can be customized to your needs and are suitable for any business, regardless of size. Contact us to get the best service and credit for missed calls.     ",
  },
];
// TWO*************************************************
export const smsN1 = [
  {
    id: 1,
    main: "VOICE CALL (SUBMISSION )    ",
    img: img2,
    name: "VOICE CALL (SUBMISSION )",
    s1: "Good news for everyone!!   ",
    s2: "You can now use Voice Call (Delivery) to deliver messages to as many customers as you want, in any language, anywhere in the country. All thanks to the latest technology. However, implementing this feature requires skilled work. Suraj Enterprise – India's leading B2B and B2C SMS Service Provider to help create voice messages and ensure that messages reach clients in their preferred language.     ",
  },
];

export const smsN2 = [
  {
    id: 1,
    desc: "With Suraj Enterprise, you can take full advantage of this feature including: B. Sending updates to your customers, letting them know about sales, campaign plans, live events, and more.     ",
    contact: "Contact us to avail of our best services.    ",
  },
];

export const smsNFeatures = [
  {
    heading: "EASY TO MANAGE",
    para: "Our software automates SMS notifications that make your job easier.    ",
    icons: "fa-solid fa-people-roof",
  },
  {
    heading: "SMS SCHEDULING",
    para: "With the SMS panel, you can schedule SMS as you like and check the SMS status    .",
    icons: "fa-solid fa-calendar-minus",
  },
  {
    heading: "SENDER ID & TEMPLATE",
    para: " You can also create a single identity and template that allows you to stand out from your brand and manage everything from a web panel. ",
    icons: "fa-solid fa-id-card",
  },
  {
    heading: "GATEWAY TIME 24 HRS    ",
    para: "Non-promotional messages can be sent to registered users 24 hours a day.    ",
    icons: "fa-solid fa-comments",
  },
  {
    heading: "UNLIMITED VALIDITY ",
    para: " We offer unlimited validity of SMS and SMS panel to ensure smooth workflow. Our powerful software allows you to send over 2 million SMS per minute at lightning speed.    ",
    icons: "fa-solid fa-reply-all",
  },
  {
    heading: "FAST DELIVERY      ",
    para: " Provides real-time reporting. The system updates all reports according to the processed messages. Track the progress of your SMS campaigns in real time.    ",
    icons: "fa-solid fa-truck-fast",
  },
  {
    heading: "NOTIFY OWN CUSTOMERS    ",
    para: " Get 24*7*365 support via email, phone, and live chat to resolve all your issues, regardless of time and place.    ",
    icons: "fa-solid fa-clock",
  },
  {
    heading: "CUSTOMER SUPPORT      ",
    para: " We offer 24/7 support via email, live chat and phone. Our professional team is known for solving all kinds of problems in the shortest possible time.       ",
    icons: "fa-solid fa-headset",
  },
  {
    heading: "TEXT/FLASH/ UNICODE MESSAGE      ",
    para: " Text Flash Unicode feature allows you to send all kinds of SMS with a single account.     ",
    icons: "fa-solid fa-envelope-open-text",
  },
];

export const smsNActivated = [
  {
    name: "Activation time instant",
  },
  {
    name: "server time 9Am to 9pm ",
  },
  {
    name: "Delivery ratio 100%",
  },
  {
    name: "File format(Excel)",
  },
  {
    name: "Delivery all numbers",
  },
  {
    name: "validity unlimited",
  },
  {
    name: "web panel",
  },
  {
    name: "delivery report",
  },
];

export const smsNPrice = [
  {
    pack: "50,000 SMS",
    vol: "₹	23 Paise ",
    price: "₹	5,750",
  },
  {
    pack: "1,00,000 SMS",
    vol: "₹	21 Paise ",
    price: "₹	10,500",
  },
  {
    pack: "5,00,000 SMS",
    vol: "₹	19.5 Paise ",
    price: "₹	19,500",
  },
  {
    pack: "10,00,000 SMS",
    vol: "₹	18 Paise ",
    price: "₹	90,500",
  },
];

export const smsSerc2 = [
  {
    id: 1,
    head: "",
    tail1: "Why do customers love Suraj Enterprise?    ",
    tail2:
      "You can record your own message and our team will send it to you.      ",
    tail3: "The message can be customized according to the instructions.    ",
    tail4: "Messages can be easily scheduled from the SMS panel.     ",
    tail5:
      "Our web-based application provides complete reporting of scheduled SMS.      ",
    tail6:
      "Our web-based application provides complete reporting of scheduled SMS. ",
    w1: "With our active 24/7/365 customer support, any issue can be resolved in no time.    ",
    w2: "The maximum voice call duration is 30 seconds. Custom APIs we build are integrated into your application to send alerts, notifications, confirmations, and more. Our messages are delivered faster than other service providers. You can create multiple branded sender IDs and templates. Our experienced employees are familiar with TRAI regulations and guidelines. So you can keep your business away from spam and errors. Contact us to exceed your expectations and achieve your business goals with a tailor-made plan for any business, regardless of size.     ",
  },
];
// THRee*************************************************
export const smsG1 = [
  {
    id: 1,
    main: "VOICE CALL (15-SEC DURATION)    ",
    img: img3,
    name: "VOICE CALL (15-SEC DURATION)    ",
    s1: " Technological advances have created new ways to interact around the world. Voice Calls – A medium that connects customers with each other regardless of demographic location or time of day. While this setting is welcome, it requires an expert to implement.    ",
    s2: "To enjoy all the voice calling features, contact a Suraj Enterprise expert. We are India's leading B2B and B2C SMS Service Provider, creating voice messages and helping ensure that your messages reach your customers or even in their preferred language.     ",
  },
];
export const smsSerc3 = [
  {
    id: 1,
    head: "Why should you use our panel?",
    tail1: "The message can be customized according to the instructions.    ",
    tail2: "Messages can be easily scheduled from the SMS panel.      ",
    tail3:
      "Our web-based application provides complete reporting of scheduled SMS.    ",
    tail4:
      "With our active 24/7/365 customer support, any issue can be resolved in no time.    ",
    tail5:
      "The maximum voice call duration is 15 seconds. Custom APIs we build are integrated into your application to send alerts, notifications, confirmations, and more.      ",
    tail6:
      "Our messages are delivered faster than other service providers.      ",
    w1: "You can create multiple branded sender IDs and templates.     ",
    w2: "Our experienced and knowledgeable staff are familiar with TRAI regulations and guidelines, so there is no misunderstanding. No matter the size of your company, contact us to take advantage of the tailor-made plan that's right for your company to exceed your expectations and achieve your business goals. You can record your own message and our team will send it to you.     ",
  },
];
export const smsG2 = [
  {
    id: 1,
    desc: "Bulk SMS service. Our long-standing customer base is a testament to our quality work and our reputation makes us among the best.Build healthy relationships between you and your clients and In order to finally bring you good business goals, please contact us.     ",
    contact: "  ",
  },
];
//services
export const smsServices = [
  {
    id: 1,
    desc: "Get the best GSM SMS service plans at affordable prices with Suraj Enterprise, one of the largest B2B and B2C SMS providers in India.     ",
    contact:
      "Here our dedicated experts focus on the key factors. Try our service for the best results.     ",
  },
];

export const smsGFeatures = [
  {
    heading: "EASY TO MANAGE",
    para: "Even if you are a tech novice, our SMS service is perfect for you. Our easy-to-use SMS service lets you send thousands of messages with just one click.     ",
    icons: "fa-solid fa-people-roof",
  },
  {
    heading: "SMS SCHEDULING",
    para: "You can easily schedule SMS and check the status via our developed SMS panel.     ",
    icons: "fa-solid fa-calendar-minus",
  },
  {
    heading: "SENDER ID & TEMPLATE",
    para: "We offer unlimited SMS panels and unlimited SMS validity, so you don't need to keep track of how many SMS have been sent or how many times the SMS panel has been used.     ",
    icons: "fa-solid fa-id-card",
  },
  {
    heading: "SMS GATEWAY TIME 12HR",
    para: "We follow rules and regulations to keep you safe. According to TRAI's rules and regulations, the promotion has only 12 hours of server time.     ",
    icons: "fa-solid fa-comments",
  },
  {
    heading: "UNLIMITED VALIDITY ",
    para: "We offer unlimited SMS panels and unlimited SMS validity, so you don't need to keep track of how many SMS have been sent or how many times the SMS panel has been used.     ",
    icons: "fa-solid fa-reply-all",
  },
  {
    heading: "FAST DELIVERY      ",
    para: "Our powerful software provides lightning-fast delivery to all networks. You can send 2,000,000 + SMS with one click.     ",
    icons: "fa-solid fa-truck-fast",
  },
  {
    heading: "REAL TIME REPORTS",
    para: "Our real-time reports are automatically updated as messages are processed. You can also track the progress of your SMS campaigns in real time.     ",
    icons: "fa-solid fa-clock",
  },
  {
    heading: "CUSTOMER SUPPORT      ",
    para: "We offer 24/7 support via email, live chat and phone. Our professional team is known for solving all kinds of problems in the shortest possible time.     ",
    icons: "fa-solid fa-headset",
  },
  {
    heading: "TEXT/FLASH/ UNICODE MESSAGE      ",
    para: " All SMS types for individual accounts. Text flash unicode.    ",
    icons: "fa-solid fa-envelope-open-text",
  },
];

export const smsGActivated = [
  {
    name: "Activation time instant",
  },
  {
    name: "server time 9Am to 9pm ",
  },
  {
    name: "Delivery ratio 100%",
  },
  {
    name: "File format(Excel)",
  },
  {
    name: "Delivery all numbers",
  },
  {
    name: "validity unlimited",
  },
  {
    name: "web panel",
  },
  {
    name: "delivery report",
  },
];

export const smsGPrice = [
  {
    pack: "50,000 SMS",
    vol: "₹	10 Paise ",
    price: "₹	5,000",
  },
  {
    pack: "1,00,000 SMS",
    vol: "₹	9.5 Paise ",
    price: "₹	9,500",
  },
  {
    pack: "5,00,000 SMS",
    vol: "₹	9 Paise ",
    price: "₹	45,000",
  },
  {
    pack: "10,00,000 SMS",
    vol: "₹	8.75 Paise ",
    price: "₹	87,000",
  },
];
