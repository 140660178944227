import React from "react";

const Services = ({ data }) => {
  return (
    <>
      {data.map((data) => {
        const { head, tail1, tail2, tail3, tail4, tail5, tail6, w1, w2 } = data;
        return (
          <div className="p-5 md:p-10 flex flex-col gap-2">
            <h2 className="text-xl py-3">{head}</h2>
            <li>{tail1}</li>
            <li>{tail2}</li>
            <li>{tail3}</li>
            <li>{tail4}</li>
            <li>{tail5}</li>
            <p>{w1}</p>
            <p>{w2}</p>
            {/* <li>{tail6}</li> */}
          </div>
        );
      })}
    </>
  );
};

export default Services;
