import React from "react";
import Footer from "../components/Footer";

const Policy = () => {
  return (
    <>
      <div className="mt-28 mb-10 lg:mx-40 mx-8 lg:p-12 p-8 shadow-2xl">
        <h1 className="text-2xl  font-medium">Privacy Policy</h1>
        <h2 className="mt-2">
          Visitor privacy is one of our top priorities at Suraj Enterprise,
          accessible through our website. This Privacy Policy describes the
          types of information Suraj Enteprise collects and records and how we
          use it. 
        </h2>
        <h2 className="mt-2">
          If you have any further questions or would like more information
          regarding our privacy policy, please feel free to contact us. 
        </h2>

        <h1 className="text-2xl  font-medium mt-8">Consent</h1>
        <h2 className="mt-2">
          By using our website, you consent to our privacy policy and agree to
          its terms. 
        </h2>

        <h1 className="text-2xl  font-medium mt-8">Information we collect</h1>
        <h2 className="mt-2">
          The personal information you request and why will be made clear to you
          at the time you request your personal information. 
        </h2>
        <h2 className="mt-2">
          If you contact us directly, we may receive additional information such
          as: B. Your name, email address, phone number, the content of messages
          and/or attachments you send to us, and any other information you
          choose to provide. 
        </h2>
        <h2 className="mt-2">
          When you register for an account, we may ask you for contact
          information such as your name, company name, address, email address
          and phone number. 
        </h2>

        <h1 className="text-2xl  font-medium mt-8">
          How we use your information
        </h1>
        <li>
          We use the information we collect in a variety of ways, including: 
        </li>
        <li>Provision, operation and maintenance of the website;</li>
        <li>improve, personalize and enhance our website;</li>
        <li>Understand and analyze how people use our website</li>
        <li>develop new products, services and features;</li>
        <li>
          Communicate with you, directly or through one of our partners,
          including customer service, to provide you with updates and other
          information related to our website and for marketing and promotional
          purposes.
        </li>
        <li>send an email</li>
        <li>Find and prevent fraud </li>

        <h1 className="text-2xl  font-medium mt-8">Log Files</h1>
        <h2 className="mt-2">
          Suraj Enterprise follows standard procedures for using log files.
          These files record when visitors visit her website. All hosting
          companies do this and it is part of our hosting service analysis.
          Information collected from log files includes Internet Protocol (IP)
          addresses, browser type, Internet Service Provider (ISP), date and
          time stamps, referring/exit pages and, in some cases, click counts.
          They are not linked to personal data. The purpose of the information
          is to analyze trends, administer the website, track user movements on
          the website and gather demographic information. 
        </h2>

        <h1 className="text-2xl  font-medium mt-8">Cookies and Web Beacons</h1>
        <h2 className="mt-2">
          Like any website, Suraj Enteprise uses "cookies". These cookies are
          used to store information such as the visitor's preferences and the
          pages on her website that the visitor viewed or visited. This
          information is used to optimize the user experience by adjusting the
          content of our website based on the visitor's browser type and other
          information. 
        </h2>

        <h1 className="text-2xl  font-medium mt-8">
          Google DoubleClick DART Cookie
        </h1>
        <h2 className="mt-2">
          Google is one of the third parties on our website. We also use
          cookies, known as DART cookies, to display advertisements based on our
          website visitor's visits and other his websites on the Internet.
          However, visitors may choose to opt out of the use of the DART cookie
          by visiting the Google Privacy Policy for the Advertising and Content
          Network at - https://policies.google.com/technologies/ads 
        </h2>

        <h1 className="text-2xl  font-medium mt-8">Our Advertising Partners</h1>
        <h2 className="mt-2">
          Some advertisers on our site may use cookies and web beacons. Our
          advertising partners are: Each advertising partner has its own privacy
          policy regarding user data policies. For easy access, we have linked
          to our privacy policy below. 
        </h2>
        <li>Google</li>
        <h2 className="mt-2">https://policies.google.com/technologies/ads</h2>

        <h1 className="text-2xl  font-medium mt-8">
          Advertising Partners Privacy Policies
        </h1>
        <h2 className="mt-2">
          Please refer to this list to review the privacy policies of each of
          Suraj Enteprise 's advertising partners.
        </h2>
        <h2 className="mt-2">
          Third-party ad servers or ad networks use technologies such as
          cookies, JavaScript, or web beacons used in each ad and link displayed
          on Suraj Enteprise that are sent directly to the user's browser. You
          will automatically receive an IP address. These technologies are used
          to measure the effectiveness of our advertising campaigns and to
          personalize the advertising content you see on the websites you visit.
        </h2>
        <h2 className="mt-2">
          Please note that Suraj Enteprise does not have access to or control
          over these cookies used by third parties
        </h2>

        <h1 className="text-2xl  font-medium mt-8">
          Third Party Privacy Policies
        </h1>
        <h2 className="mt-2">
          Suraj Enterprise's privacy policy does not apply to other advertisers
          or websites. Therefore, we encourage you to refer to the respective
          privacy policies of these third-party ad servers for more detailed
          information. It may contain practices and instructions on how to
          disable certain options.
        </h2>
        <h2 className="mt-2">
          You can disable cookies in your individual browser options. For more
          information on cookie management in specific web browsers, please
          refer to his website for each of your browsers. 
        </h2>

        <h1 className="text-2xl  font-medium mt-8">
          CCPA Privacy Rights (Do Not Sell My Personal Information)
        </h1>
        <h2 className="mt-2">
          Under the CCPA, California consumers, among other rights, have the
          following rights:
        </h2>
        <h2 className="mt-2">
          Require companies that collect personal information from consumers to
          disclose the categories and specific pieces of personal information
          they collect about consumers.
        </h2>
        <h2 className="mt-2">
          Request that businesses delete all personal information they have
          collected about consumers.
        </h2>
        <h2 className="mt-2">
          We request that businesses that sell consumer personal information not
          sell consumer personal information.
        </h2>
        <h2 className="mt-2">
          We will respond to your request within one month. If you wish to
          exercise any of these rights, please contact us.  
        </h2>

        <h1 className="text-2xl  font-medium mt-8">
          GDPR Data Protection Rights
        </h1>
        <h2 className="mt-2">
          We want you to be fully aware of all your data protection rights. Each
          user has the following rights:
        </h2>
        <h2 className="mt-2">
          Right of access – You have the right to request a copy of your
          personal data. A small fee may apply for this service.
        </h2>
        <h2 className="mt-2">
          Right to rectification – You have the right to request that
          information be rectified that you believe is inaccurate. You also have
          the right to request that information that you believe to be
          incomplete be completed. Right to erasure – You have the right to
          request erasure of your personal data under certain conditions.
        </h2>

        <h1>
          Right to restriction of processing - You have the right to request
          restriction of processing of your personal data under certain
          conditions.
        </h1>

        <h1>
          Right to object to processing - You have the right to object to
          processing of your personal data under certain conditions.
        </h1>

        <h1>
          Right to data portability – Under certain conditions, you have the
          right to request that the data we collect be transferred to another
          organization or directly to you.
        </h1>

        <h1>
          We will respond to your request within one month. If you wish to
          exercise any of these rights, please contact us.  
        </h1>

        <h1 className="text-2xl  font-medium mt-8">Children's Information</h1>
        <h2 className="mt-2">
          Another part of our priority is protecting children while using the
          Internet. Parents and legal guardians are encouraged to monitor,
          participate in and/or monitor and guide online activities.
        </h2>
        <h2 className="mt-2">
          Suraj Enterprise does not knowingly collect personally identifiable
          information from children under the age of 13. If you believe that
          your child has provided this type of information to our website, we
          strongly encourage you to contact us immediately. We will do our best
          to promptly retrieve such information from deleted records. 
        </h2>
        <h1 className="text-2xl  font-medium mt-8">Refund Policy</h1>
        <h2 className="mt-2">
        At Suraj Enterprise, we stand by the quality and reliability of our products. 
        While we do not offer a refund policy on purchases, we are committed to ensuring 
        your satisfaction. We believe in transparency and encourage our customers to 
        experience our products through our comprehensive demo offerings.
        </h2>
        <h2 className="mt-2">
        Our Commitment to Quality: Each product at Suraj Enterprise is crafted with utmost 
        care and attention to detail. We prioritize quality assurance throughout our 
        manufacturing and testing processes to deliver products that meet the highest 
        standards of performance and durability. 
        </h2>
        <h2 className="mt-2">
        Experience Before You Commit: We understand that making a purchase decision can be 
        important and sometimes challenging. That's why we provide robust demo options to 
        allow you to experience our products firsthand. Our demos are designed to give you 
        a complete understanding of how our products work and their suitability for your needs. 
        </h2>
        <h2 className="mt-2">
        Customer Support: Our dedication to customer satisfaction extends beyond the purchase. 
        We offer exceptional customer support to address any questions or concerns you may have 
        before, during, or after your purchase. Our knowledgeable team is here to assist you 
        every step of the way.
        </h2>
        <h2 className="mt-2">
        Join Us: Discover the difference with Suraj Enterprise. Explore our range of products 
        and experience the quality and innovation that sets us apart. We invite you to browse 
        through our offerings and take advantage of our demo opportunities to find the perfect 
        solution for your needs. 
        </h2>
      </div>

      <Footer />
    </>
  );
};

export default Policy;
